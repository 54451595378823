import styled from 'styled-components';
import { Link } from "react-router-dom";

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: 30px;

    @media (max-width: 768px) {
        margin-top: 70px;
    }
`;

export const TitleBody = styled.div`
    font-size: 30px;
    font-weight: bold;
    color: #7BC7FF;
    margin-left: 15px;
    height: 50px;
    width: 200px;
`;

export const DivMultimidia = styled.div`
    overflow: hidden;
    position: relative;
    z-index: 10;
    -webkit-border-radius: 15px;
    border-radius: 15px;
`;

export const QuatroFotos = styled(Link)`
    width: 100%;
    height: 302px;
    display: grid;
`;

export const Cima = styled.div`
    display: flex;
    width: 100%;
    height: 151px;
`;

export const Um = styled.div`
    max-width: 50%;
    min-width: 50%;
`;

export const Dois = styled.div`
    max-width: 50%;
    min-width: 50%;
`;