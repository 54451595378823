import React from 'react';
import * as S from './styled';

const ButtonPrimary = (props) => {

    return (
        <S.CustonButtonPrimary style={{ width: props.size, height: "35px" }} cor={props.cor} efeito={props.efeito}>
            <S.TextButton>{props.label}</S.TextButton>
        </S.CustonButtonPrimary>
    )
}

export default ButtonPrimary;