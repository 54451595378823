import React from 'react';

function Menor(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28.601" height="76.275" viewBox="0 0 28.601 76.275" fill="none" {...props}>
            <path id="Caminho_71" data-name="Caminho 71" d="M3.292,0,0,5.883H.025L21.559,40.934.165,70.581H.1l3.188,5.694L28.6,41.2Z" transform="translate(28.601 76.275) rotate(180)" fill="#cbcbcb"/>
        </svg>
    )
}

export default Menor;