import React from 'react';

function SiteLinks(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="39.061" height="39.061" viewBox="0 0 39.061 39.061" fill="none" {...props}>
            <g id="Grupo_2774" data-name="Grupo 2774" transform="translate(0.288)">
                <path id="Caminho_63" data-name="Caminho 63" d="M157.191,39.061a19.536,19.536,0,0,1-7.6-37.526,19.535,19.535,0,0,1,15.2,35.991A19.409,19.409,0,0,1,157.191,39.061Zm0-38.061a18.53,18.53,0,1,0,18.531,18.531A18.551,18.551,0,0,0,157.191,1Z" transform="translate(-137.949)" fill="#004070"/>
                <g id="Grupo_2775" data-name="Grupo 2775" transform="translate(2.513 -7.656)">
                <g id="Grupo_2776" data-name="Grupo 2776" transform="translate(0.199 21.656)">
                    <path id="Caminho_408" data-name="Caminho 408" d="M26.079,440.566c.425,0,.751.01,1.059.033a4.829,4.829,0,0,1,3.309,1.605,4.9,4.9,0,0,1,1.177,3.5,4.443,4.443,0,0,1-.252,1.319l-.279-.1-.227-.085a4.464,4.464,0,0,0,.043-2.679,4.288,4.288,0,0,0-3.922-3.031c-.3-.006-.632-.009-1.039-.009H5.277a4.313,4.313,0,0,0-3.664,6.711A4.207,4.207,0,0,0,5.1,449.711c2.882.006,5.847.01,9.065.01,3.78,0,7.827,0,12.735-.014a5.659,5.659,0,0,0,1.059-.135l.252-.047.072.192.109.29a4.377,4.377,0,0,1-1.456.26c-.307.005-.641.007-1.051.007H13.848c-3.378,0-6.114,0-8.613-.009a4.959,4.959,0,0,1-2.57-.656A4.707,4.707,0,0,1,.9,447.656a4.64,4.64,0,0,1,.37-5.08,4.626,4.626,0,0,1,4-2H24.829c.21,0,.422,0,.628,0s.415,0,.622,0Zm0-.316c-.416,0-.833.01-1.249.01H5.268a4.933,4.933,0,0,0-4.254,2.135A4.955,4.955,0,0,0,.621,447.8a4.881,4.881,0,0,0,4.613,2.781q4.307.01,8.614.009l10.6,0h.025l1.408,0c.352,0,.7,0,1.056-.008a4.606,4.606,0,0,0,1.855-.4l-.38-1.012a10.784,10.784,0,0,1-1.516.221q-6.368.012-12.734.014-4.532,0-9.064-.01a3.982,3.982,0,1,1,.179-7.962h19.4l1.27,0c.344,0,.689,0,1.033.009a3.948,3.948,0,0,1,3.625,2.8,4.24,4.24,0,0,1-.14,2.777l1.084.406a4.686,4.686,0,0,0,.389-1.708,5.078,5.078,0,0,0-4.779-5.433c-.36-.027-.721-.034-1.082-.034" transform="translate(-0.001 -440.25)" fill="#004070"/>
                    <path id="Caminho_410" data-name="Caminho 410" d="M312.466,488.433q.266-1.083.532-2.165c.131-.537.131-.537.682-.537h.595c-.017.107-.023.187-.043.263-.391,1.484-.785,2.967-1.172,4.452-.049.189-.124.288-.34.271a5.089,5.089,0,0,0-.757,0c-.212.015-.292-.071-.339-.269-.185-.776-.387-1.548-.643-2.329q-.181.727-.361,1.455c-.007.031-.009.063-.016.093-.246,1.11-.246,1.114-1.387,1.039a.321.321,0,0,1-.241-.166c-.419-1.573-.825-3.149-1.233-4.725,0-.008.009-.019.041-.083.389,0,.807-.015,1.222.014.075.005.171.186.2.3.181.7.346,1.409.517,2.114.022.091.049.181.1.365.083-.329.148-.571.206-.815.136-.573.277-1.144.4-1.72.044-.205.135-.278.342-.262.3.023.681-.081.885.066.188.136.194.53.269.813.161.609.315,1.219.472,1.829l.07,0" transform="translate(-288.295 -482.84)" fill="#004070"/>
                    <path id="Caminho_411" data-name="Caminho 411" d="M95.222,485.929c.409,0,.819-.012,1.227.011.078,0,.19.139.215.233.2.752.377,1.51.562,2.265a.288.288,0,0,0,.115.176l.644-2.685c.354,0,.691-.015,1.025.01a.351.351,0,0,1,.228.222c.2.729.385,1.464.575,2.2.025.1.057.2.11.376l.713-2.782h1.129c-.08.325-.155.649-.24.969-.33,1.248-.669,2.493-.99,3.743-.05.193-.128.26-.319.25-.335-.018-.765.1-.98-.062s-.217-.608-.3-.932c-.135-.508-.263-1.017-.4-1.526,0-.014-.029-.022-.079-.057l-.624,2.572c-.4,0-.8.007-1.194-.008a.263.263,0,0,1-.181-.162q-.633-2.344-1.249-4.693a.409.409,0,0,1,.014-.117" transform="translate(-89.19 -483.038)" fill="#004070"/>
                    <path id="Caminho_412" data-name="Caminho 412" d="M206.3,485.923h1.136c-.083.338-.155.65-.236.959-.326,1.239-.661,2.476-.977,3.718-.057.225-.149.32-.383.295a1.968,1.968,0,0,0-.536,0c-.379.066-.505-.115-.579-.457a18.589,18.589,0,0,0-.59-2.12c-.039.153-.081.305-.118.459-.15.623-.306,1.246-.445,1.872a.269.269,0,0,1-.326.245,7.055,7.055,0,0,0-.821,0c-.215.015-.286-.083-.335-.273-.354-1.382-.717-2.761-1.078-4.141-.047-.179-.1-.356-.163-.571.441,0,.841-.016,1.237.013.087.006.209.157.237.263.191.7.361,1.4.537,2.107a.756.756,0,0,0,.142.33l.673-2.714c.338,0,.675-.017,1.008.012.083.008.194.155.223.257.225.808.433,1.622.714,2.442l.68-2.7" transform="translate(-188.157 -483.019)" fill="#004070"/>
                    <path id="Caminho_413" data-name="Caminho 413" d="M452.156,550.349l3.932,1.46-1.227.7,1.592,1.533-.578.544-1.532-1.577-.728,1.272-1.459-3.928" transform="translate(-424.508 -544.243)" fill="#004070"/>
                </g>
                </g>
            </g>
        </svg>
    )
}

export default SiteLinks;