import React from 'react';
import * as S from './styles';
import { Container, Row, Col } from 'reactstrap';
import LogoPq from '../../assets/img/logo_pq.png';
import ButtonPdf from '../../components/ButtonPdf';

const EstatudoSobre = (props) => {
    
    return (
        <S.Fundo>
            <Container style={{ maxWidth: "80%", paddingBottom: "40px" }}>
                <Row>
                    <Col xl={12}>
                        <S.DivTitulos>
                            <img src={LogoPq} alt="" />
                            <S.TitleBody>Estatuto</S.TitleBody>
                        </S.DivTitulos>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} xl={6}>
                        <S.LeftEstatuto>
                            <S.TitleEstatuto>Atuamos para congregar</S.TitleEstatuto>
                            <S.TextEstatuto>Atuamos para congregar os servidores da extinta Caixa Econômica Estadual na defesa de seus interesses e fins beneficentes, sociais e assistenciais.</S.TextEstatuto>
                        </S.LeftEstatuto>
                    </Col>
                    <Col md={12} xl={6}>
                        <a target="_blank" rel="noreferrer" href={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${props.estatuto}`} style={{ textDecoration: "none" }}>
                            <ButtonPdf />
                        </a>
                    </Col>
                </Row>
            </Container>
        </S.Fundo>
    )
}

export default EstatudoSobre;