import React from 'react';

function Email(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="46.907" height="38.563" viewBox="0 0 46.907 38.563" fill="none" {...props}>
            <g id="picto_novo_email" transform="translate(-3959 -13588.437)">
                <path id="Caminho_77" data-name="Caminho 77" d="M2.234,7.231H0V3.615A3.49,3.49,0,0,1,3.351,0H43.557a3.49,3.49,0,0,1,3.351,3.615V34.948a3.49,3.49,0,0,1-3.351,3.615H3.351A3.49,3.49,0,0,1,0,34.948V9.641H2.234V34.948a1.163,1.163,0,0,0,1.117,1.205H43.557a1.163,1.163,0,0,0,1.117-1.205V3.615A1.163,1.163,0,0,0,43.557,2.41H3.351A1.163,1.163,0,0,0,2.234,3.615Z" transform="translate(3959 13588.437)" fill="#fff"/>
                <path id="Caminho_78" data-name="Caminho 78" d="M41.2,15.926h3.132L33.164,27.977,22,15.926h3.131L33.164,24.6Z" transform="translate(3949.285 13585.767)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default Email;