import styled from 'styled-components';

export const Fundo = styled.div`
    min-width: 100%;
    min-height: 666px;
    display: grid;
`;

export const Infos = styled.div`
    margin-top: -20px;
`;

export const Title = styled.text`
    font-size: 30px;
    font-weight: 600;
    color: #FFFFFF;
`;

export const Right = styled.div`
    display: grid;
    width: 100%;
    height: 333px;
    background-color: #004070;
    border-radius: 15px;
`;

export const DivText = styled.div`
    margin: 30px 30px 0 30px;
    display: grid;
`;

export const DivEnd = styled.div`
    display: flex;
    height: 65px;
    justify-content: center;    
`;

export const Text = styled.div`
    font-size: 15px;
    color: #FFFFFF;

    @media (min-width: 768px) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;     /* fallback */
        max-height: 320px;      /* fallback */
        -webkit-line-clamp: 10; /* number of lines to show */
        -webkit-box-orient: vertical;
    }  
    @media (max-width: 768px) {
        
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px; 
        max-height: 80px;     
        -webkit-line-clamp: 5; 
        -webkit-box-orient: vertical;
    }
`;

export const End = styled.div`
    display: grid;
    margin-left: 15px;
`;

export const TextNormal = styled.text`
    font-size: 13px;
    color: #FFFFFF;
`;

export const TextBold = styled.text`
    font-size: 13px;
    font-weight: bold;
    color: #FFFFFF;
`;

export const Divider = styled.hr`
    border-top: ${({ cor }) => { if (cor === "primary") { return '1px groove #004070'; } else { return '1px groove #7BC7FF'; }}};
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    width: 90%;
    margin: 24px 5%;
`;