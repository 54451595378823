import React from 'react';
//import * as S from './styles';

import { useState, useRef, useEffect } from 'react'
import './slider.css'
import './thumb.css'
import FrequenciaAzulClaro from '../../assets/img/TABLET_FREQUENCIA_AZUL_CLARO.png';
import FrequenciaAzulEscuro from '../../assets/img/TABLET_FREQUENCIA_AZUL_ESCURA.png';
import FrequenciaBranco from '../../assets/img/TABLET_FREQUENCIA_AZUL_BRANCA.png';

const Slider = ({ percentage = 0, onChange, size, cor }) => {
  const [position, setPosition] = useState(0)
  const [marginLeft, setMarginLeft] = useState(0)
  const [progressBarWidth, setProgressBarWidth] = useState(0)

  const rangeRef = useRef()
  const thumbRef = useRef()

  useEffect(() => {
    const rangeWidth = rangeRef.current.getBoundingClientRect().width
    const thumbWidth = thumbRef.current.getBoundingClientRect().width
    const centerThumb = (thumbWidth / 100) * percentage * -1
    const centerProgressBar =
      thumbWidth + (rangeWidth / 100) * percentage - (thumbWidth / 100) * percentage
    setPosition(percentage)
    setMarginLeft(centerThumb)
    setProgressBarWidth(centerProgressBar)
  }, [percentage])

  return (
      <div className=
      {cor === "azul_claro" || cor === "azul_escuro" ? 'azul-slider-container'
        : size === "medio" ? 'md-slider-container' : 'slider-container-gr'
      }
      >
        <div
          className={size === "medio" ? 'progress-bar-cover' : 'gr-progress-bar-cover'}
          style={{
            width: `${progressBarWidth}px`,
          }}
        ></div>
        {cor === "branco" ?  
          <img src={FrequenciaBranco} alt="" height="132px" width="100%" style={{ marginTop: "-55px", zIndex: "2", position: "absolute" }}/> 
        :
        <img src={cor === "azul_claro" ? FrequenciaAzulClaro : FrequenciaAzulEscuro} alt="" height="132px" width="100%" style={{ marginTop: "-30px", zIndex: "2", position: "absolute" }}/> 
        }
        <div
          className='thumb'
          ref={thumbRef}
          style={{
            left: `${position}%`,
            marginLeft: `${marginLeft}px`,
          }}
        ></div>
        <input
          type='range'
          value={position}
          ref={rangeRef}
          step='0.01'
          className='range'
          onChange={onChange}
        />
      </div>
      );
};

export default Slider;