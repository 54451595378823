import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import MultimidiaPq from '../../components/Multimidia';
// import Galeria from '../../components/Galeria';
// import Audio from '../../components/Audio';
import Axios from '../../utils/Axios';
// import HTMLReactParser from 'html-react-parser';
import MaiorPag from '../../assets/svg/Maior';
import MenorPag from '../../assets/svg/Menor';

const Multimidia = () => {
  const [dados, setDados] = useState([]);

  //paginacao
  const [itemsPorPage] = useState(9);
  const [paginaAtual, setPaginaAtual] = useState(0);
  const pages = Math.ceil(dados.length / itemsPorPage);
  const startIndex = paginaAtual * itemsPorPage;
  const endIndex = startIndex + itemsPorPage;
  const currentItens = dados.slice(startIndex, endIndex);

  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/multimidias`).then((response) => {
      setDados(response.data)
    })
  }, []);

  return (
    <>
      <Header min="true" name="Multimídia"/>
      {/* <MultimidiaPq height={"500px"} minWidth={"100%"} maxWidth={"100%"} /> */}
      <Container style={{ maxWidth: "94%"}}>
        <Row>
        {currentItens !== undefined && currentItens.map((item, index) => (
 
        // {dados !== undefined && dados.map((item, index) => (
          <Col md={6} xl={4} sm={12} style={{ marginTop: "70px" }} key={index}>
            {parseInt(item.modelo) === 1 ?
              <S.DivMultimidia>
                <MultimidiaPq height={"302px"} minWidth={"100%"} maxWidth={"100%"} urlYoutube={item.urlYoutube} />
              </S.DivMultimidia>
              : 
              item.imagem0 && item.imagem1 && item.imagem2 && item.imagem3 !== null ?
                <S.QuatroFotos to={"/multimidia/detalhes/"+item.id}>
                  <S.Cima>
                    <S.Um>
                      <img src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${item.imagem0}`} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
                    </S.Um>
                    <S.Dois>
                      <img src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${item.imagem1}`} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
                    </S.Dois>
                  </S.Cima>
                  <S.Cima>
                    <S.Um>
                      <img src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${item.imagem2}`} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
                    </S.Um>
                    <S.Dois>
                      <img src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${item.imagem3}`} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
                    </S.Dois>
                  </S.Cima>
                </S.QuatroFotos>
              :
                <S.DivMultimidia2 to={"/multimidia/detalhes/"+item.id}>
                  {item.imagem0 ?
                    <img 
                      src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${item.imagem0}`} 
                      alt="" 
                      style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
                    />
                  : item.imagem1 ?
                    <img 
                      src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${item.imagem1}`} 
                      alt="" 
                      style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
                    />
                  : item.imagem2 ?
                    <img 
                      src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${item.imagem2}`} 
                      alt="" 
                      style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
                    />
                  : item.imagem3 ?
                    <img 
                      src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${item.imagem3}`} 
                      alt="" 
                      style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
                    />
                  : ""
                  } 
                </S.DivMultimidia2>
              }
            <S.Grid>
              {/* <S.Title>{item.cartola}</S.Title> */}
              <S.SubTitle>{item.titulo}</S.SubTitle>
              {/* <S.Texto>{HTMLReactParser(String(item.texto))}</S.Texto> */}
            </S.Grid>
          </Col>
        ))}




          
          {/* <Col md={6} xl={4} sm={12} style={{ marginTop: "50px" }}>
            <MultimidiaPq height={"302px"} minWidth={"100%"} maxWidth={"100%"} />
            <S.Grid>
              <S.Title>Conselho de Delegados(as) Sindicais</S.Title>
              <S.SubTitle>Conselho de Delegados(as) Sindicais</S.SubTitle>
              <S.Texto>O SINDICAIXA reuniu nesta sexta-feira (13), em Porto Alegre, o seu Conselho de Delegados(as) Sindicais para fazer um balanço da campanha salarial e sobre as perspectivas para o futuro, sobretudo neste ano importante de eleições gerais.</S.Texto>
            </S.Grid>
          </Col>
          <Col md={6} xl={4} sm={12} style={{ marginTop: "50px" }}>
            <MultimidiaPq height={"302px"} minWidth={"100%"} maxWidth={"100%"} />
            <S.Grid>
              <S.Title>Conselho de Delegados(as) Sindicais</S.Title>
              <S.SubTitle>Conselho de Delegados(as) Sindicais</S.SubTitle>
              <S.Texto>O SINDICAIXA reuniu nesta sexta-feira (13), em Porto Alegre, o seu Conselho de Delegados(as) Sindicais para fazer um balanço da campanha salarial e sobre as perspectivas para o futuro, sobretudo neste ano importante de eleições gerais.</S.Texto>
            </S.Grid>
          </Col>
          <Col md={6} xl={4} sm={12} style={{ marginTop: "50px" }}>
            <Galeria />
            <S.Grid style={{ marginTop: "20px" }}>
              <S.Title>Conselho de Delegados(as) Sindicais</S.Title>
              <S.SubTitle>Conselho de Delegados(as) Sindicais</S.SubTitle>
              <S.Texto>O SINDICAIXA reuniu nesta sexta-feira (13), em Porto Alegre, o seu Conselho de Delegados(as) Sindicais para fazer um balanço da campanha salarial e sobre as perspectivas para o futuro, sobretudo neste ano importante de eleições gerais.</S.Texto>
            </S.Grid>
          </Col>
          <Col md={6} xl={4} sm={12} style={{ marginTop: "150px" }}>
            <Audio size="medio" cor="branco" />
            <S.Grid style={{ marginTop: "20px" }}>
              <S.Title>Conselho de Delegados(as) Sindicais</S.Title>
              <S.SubTitle>Conselho de Delegados(as) Sindicais</S.SubTitle>
              <S.Texto>O SINDICAIXA reuniu nesta sexta-feira (13), em Porto Alegre, o seu Conselho de Delegados(as) Sindicais para fazer um balanço da campanha salarial e sobre as perspectivas para o futuro, sobretudo neste ano importante de eleições gerais.</S.Texto>
            </S.Grid>
          </Col>
          <Col md={6} xl={4} sm={12} style={{ marginTop: "150px" }}>
            <Audio size="medio" cor="branco" />
            <S.Grid style={{ marginTop: "20px" }}>
              <S.Title>Conselho de Delegados(as) Sindicais</S.Title>
              <S.SubTitle>Conselho de Delegados(as) Sindicais</S.SubTitle>
              <S.Texto>O SINDICAIXA reuniu nesta sexta-feira (13), em Porto Alegre, o seu Conselho de Delegados(as) Sindicais para fazer um balanço da campanha salarial e sobre as perspectivas para o futuro, sobretudo neste ano importante de eleições gerais.</S.Texto>
            </S.Grid>
          </Col>
          <Col md={6} xl={4} sm={12} style={{ marginTop: "150px" }}>
            <Audio size="medio" cor="branco" />
            <S.Grid style={{ marginTop: "20px" }}>
              <S.Title>Conselho de Delegados(as) Sindicais</S.Title>
              <S.SubTitle>Conselho de Delegados(as) Sindicais</S.SubTitle>
              <S.Texto>O SINDICAIXA reuniu nesta sexta-feira (13), em Porto Alegre, o seu Conselho de Delegados(as) Sindicais para fazer um balanço da campanha salarial e sobre as perspectivas para o futuro, sobretudo neste ano importante de eleições gerais.</S.Texto>
            </S.Grid>
          </Col> */}
        </Row>
        <Row>
          {currentItens.length > 0 ?
            <S.DivPagination>
              <S.Paginacao disabled={paginaAtual === 0 ? true : false} onClick={(e) => setPaginaAtual(Number(paginaAtual-1))}><MenorPag style={{ width: "25px", height: "25px", marginLeft: "-3px" }} /></S.Paginacao>
              {Array.from(Array(pages), (item, index) => {
                if(paginaAtual === index){
                  return <S.PaginacaoAtivo value={index} onClick={(e) => setPaginaAtual(Number(e.target.value))}>{index + 1}</S.PaginacaoAtivo>
                } else {
                  return <S.Paginacao value={index} onClick={(e) => setPaginaAtual(Number(e.target.value))}>{index + 1}</S.Paginacao>
                }
              })}
              <S.Paginacao disabled={paginaAtual === pages-1 ? true : false } onClick={() => setPaginaAtual(Number(paginaAtual+1))}><MaiorPag style={{ width: "25px", height: "25px", marginLeft: "0px" }} /></S.Paginacao>
            </S.DivPagination>
            :
            <S.DivMensagem>
              <S.Title>Não foi encontrado nenhum sindicalizado!</S.Title>
            </S.DivMensagem>
          }
        </Row>
      </Container>  
      <Footer />
      <Assinatura />
    </>
  );
};

export default Multimidia;