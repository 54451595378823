import React from 'react';
import {Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Sedes from './pages/Sedes';
import Links from './pages/Links';
import Contato from './pages/Contato';
import Multimidia from './pages/Multimidia';
import MultimidiaDetalhes from './pages/Multimidia/MultimidiaDetalhes';
import NoticiasDetalhes from './pages/Noticias/NoticiasDetalhes';
import Noticias from './pages/Noticias';
import Jornal from './pages/Jornal';

export default function MainRoutes(){
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sobre" element={<Sobre />} />
            <Route path="/sedes" element={<Sedes />} />
            <Route path="/links" element={<Links />} />
            <Route path="/contato" element={<Contato />} />
            <Route path="/noticias" element={<Noticias />} />
            <Route path="/noticias/detalhes/:id" element={<NoticiasDetalhes />} />
            <Route path="/multimidia" element={<Multimidia />} />
            <Route path="/multimidia/detalhes/:id" element={<MultimidiaDetalhes />} />
            <Route path="/jornal" element={<Jornal />} />
        </Routes>
    );
}