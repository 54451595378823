import React from 'react';

function MenorPq(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.47" height="41.257" viewBox="0 0 15.47 41.257" fill="none" {...props}>
            <path id="Caminho_372" data-name="Caminho 372" d="M1.781,0,0,3.182H.014L11.661,22.141.089,38.177H.054l1.724,3.08L15.471,22.284Z" transform="translate(15.471 41.257) rotate(180)" fill="#fcfcfc"/>
        </svg>
    )
}

export default MenorPq;