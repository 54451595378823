import styled from 'styled-components';
import Face from '../../../assets/svg/FaceLinks';
import Twitter from '../../../assets/svg/TwitterLinks';
import Whats from '../../../assets/svg/WhatsLinks';
import AMais from '../../../assets/svg/AMais';
import AMenos from '../../../assets/svg/AMenos';
import { Link } from "react-router-dom";

export const DivButton = styled.div`
    display: flex;
    width: 100%;
    margin-top: 65px;
    justify-content: space-between;
`;

export const DivCompartilhar = styled.div`
    display: flex;
    gap: 30px;
    text-align: center;
    align-items: center;
`;

export const Texto = styled.text`
    color: #CBCBCB;
    font-size: 13px;
`;

export const DivFonte = styled.div`
    display: flex;
    gap: 10px;
    text-align: center;
    align-items: center;
`;

export const CustomFace = styled(Face)`
    width: 30px;
    height: 30px;

    & path {
        fill: #CBCBCB;
    }
`;

export const CustomTwitter = styled(Twitter)`
    width: 30px;
    height: 30px;

    & path {
        fill: #CBCBCB;
    }
`;

export const CustomWhats = styled(Whats)`
    width: 30px;
    height: 30px;

    & path {
        fill: #CBCBCB;
    }
`;

export const CustomAMais = styled(AMais)`
    width: 30px;
    height: 30px;
`;

export const CustomAMenos = styled(AMenos)`
    width: 30px;
    height: 30px;
`;

export const Title = styled.div`
    font-size: 
    ${({ fonte }) => { 
        if (fonte === 3) { 
            return '29px' 
        } else if (fonte === 4) {
            return '33px' 
        } else if(fonte === 1) { 
            return '21px' 
        } else if(fonte === 0) {
            return '17px'
        } else { 
            return '25px' 
        }
    }};
    font-weight: bold;
    color: #7BC7FF;
    margin-top: 40px;
`;

export const Title2 = styled.div`
    color: #FFFFFF;
    font-size: 35px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: 1420px) {
        font-size: 30px;
    }
    @media (max-width: 1200px) {
        font-size: 35px;
    }
`;

export const SubTitle = styled.div`
    font-size:
    ${({ fonte }) => { 
        if (fonte === 3) { 
            return '39px' 
        } else if (fonte === 4) {
            return '43px' 
        } else if(fonte === 1) { 
            return '31px' 
        } else if(fonte === 0) {
            return '27px'
        } else { 
            return '35px' 
        }
    }};
    font-weight: bold;
    color: #004070;
    margin-top: 10px;
`;

export const Text = styled.div`
    font-size:
    ${({ fonte }) => { 
        if (fonte === 3) { 
            return '24px' 
        } else if (fonte === 4) {
            return '28px' 
        } else if(fonte === 1) { 
            return '16px' 
        } else if(fonte === 0) {
            return '12px'
        } else { 
            return '20px' 
        }
    }};
    color: #707070;
    margin-top: 20px;

    img {
        max-width: 100%;
    }
`;

export const Text3 = styled.p`
    color: ${({ cor }) => { if (cor === "primary") { return '#004070'; } else { return '#FFFFFF'; }}};
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.3; 
    max-height: 150px;     
    -webkit-line-clamp: 7; 
    -webkit-box-orient: vertical;

    @media (max-width: 1500px) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 1.4; 
        max-height: 40px;     
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
        margin-top: -5px;
    } 
    @media (max-width: 1200px) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 1.4; 
        max-height: 80px;     
        -webkit-line-clamp: 4; 
        -webkit-box-orient: vertical;
        margin-top: -5px;
    }
`;

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #004070FA;
    display: flex;
    justify-content: center;
    padding-top: 10%;
`;

export const Fundo = styled.div`
    display: grid;
    background-color: ${({ cor }) => { if (cor === "secundary") { return '#7BC7FF'; } else { return '#004070'; }}};
    max-width: 100%;
    max-height: 400px;
    min-height: 400px;
    border-radius: 15px; 
    padding: 50px 20px;

    @media (max-width: 1200px) {
        max-height: 470px;
        min-height: 470px;
    }
`;

export const Frente = styled.div`
    z-index: 10;
    position: relative;
    margin-top: 30px;
`;

export const CustomButton = styled.button`
    border: none;
    background-color: #FFFFFF;
`;

export const Legenda = styled.div`
    margin-top: 27%;
    color: #FFFFFF;
`;

export const Center = styled.div`
    display: flex;
    justify-content: center;
`;

export const CenterYoutube = styled.div`
    display: flex;
    justify-content: center;
    height: 700px;
`;

export const DivCard = styled.div`
    text-decoration: none;
    max-width: 100%;
    max-height: 100%;
`;

export const DivLink = styled(Link)`
    text-decoration: none;
    min-width: 50%;
    min-height: 50%;
`;

export const Textos = styled.div`
    display: grid;
    height: 260px;
`;

export const Lapela = styled.div`
    color: ${({ cor }) => { if (cor === "white") { return '#FFFFFF'; } else { return '#7BC7FF'; }}};
    font-size: 15px;
    font-weight: bold;
`;

export const Botoes = styled.div`
    display: grid;
    height: 50px;
    bottom: 0;
    width: 100%;
    margin-top: -5px;

    @media (max-width: 1200px) {
        margin-top: 70px;
    } 
`;

export const Divider = styled.hr`
    border-top: ${({ cor }) => { if (cor === "primary") { return '1px groove #004070'; } else if(cor === "white") { return '1px groove #CBCBCB' } else { return '1px groove #7BC7FF'; }}};
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    width: 100%;
    margin: 24px 0;
`;

export const RedeSociais = styled.div`
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    width: 100%;

    @media (max-width: 1500px) {
        gap: 15px;
    } 
    @media (max-width: 1300px) {
        gap: 5px;
    } 
    @media (max-width: 1200px) {
        gap: 30px;
    } 
`;

export const Text2 = styled.div`
    color: ${({ cor }) => { if (cor === "primary") { return '#004070'; } else if(cor === "white") { return '#CBCBCB' } else { return '#7BC7FF'; }}};
    font-size: 13px;
`;

export const Duplo = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 1200px) {
        display: grid;
    } 
`;

export const FundoDuplo = styled.div`
    display: grid;
    background-color: ${({ cor }) => { if (cor === "secundary") { return '#A5D4F6'; } else if(cor === "white") { return '#eeeeee'; } else { return '#004070'; }}};
    max-width: 100%;
    max-height: 400px;
    min-height: 400px;
    border-radius: 0 15px 15px 0; 
    padding: 50px 20px;

    @media (max-width: 1200px) {
        border-radius: 15px; 
        padding: 50px 20px;
        max-height: 220px;
        min-height: 220px;
    }
`;

export const ImagemDuplo = styled.div`
    min-width: 50%;
    max-height: 400px;
    min-height: 400px;
    background-image: url(${(picture) => `http://sindicaixars.web227.uni5.net/api/storage/app/public/${picture.picture}`});
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 15px 0 0 15px;
    background-size: cover;
    background-color: #004070;
    z-index: 1;

    @media (max-width: 1200px) {
        width: 100%;
        border-radius: 15px 15px 0 0px;
        max-height: 250px;
        min-height: 250px;
        padding-left: -40px;
        background-size: cover;
    }
`;

export const TextosDuplo = styled.div`
    display: grid;
    height: 260px;

    @media (max-width: 1200px) {
        height: 190px;
    }
`;

export const TitleDuplo = styled.div`
    color: ${({ cor }) => { if (cor === "white") { return '#004070' } else { return '#FFFFFF' }}};
    font-size: 35px;
    margin-top: -10px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: 1200px) {
        margin-top: -30px;
    }
    @media (max-width: 1000px) {
        font-size: 30px;
    }
`;

export const TextDuplo = styled.p`
    color: ${({ cor }) => { if (cor === "primary") { return '#004070'; } else if(cor === "white") { return '#000000' } else { return '#FFFFFF'; }}};
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.3; 
    max-height: 210px;     
    -webkit-line-clamp: 7; 
    -webkit-box-orient: vertical;
        
    @media (max-width: 1700px) {
        max-height: 138px;
    } 
    @media (max-width: 1200px) {
        -webkit-line-clamp: 0;
        max-height: 0px;  
    }
`;

export const BotoesDuplo = styled.div`
    display: grid;
    height: 50px;
    bottom: 0;
    width: 100%;
    margin-top: -5px;

    @media (max-width: 1200px) {
        margin-top: -80px;
    } 
`;

export const Imagem = styled.div`
    max-height: 400px;
    min-height: 400px;
    background-image: url(${(picture) => `http://sindicaixars.web227.uni5.net/api/storage/app/public/${picture.picture}`});
    background-repeat: no-repeat;
    background-color: ${({ color }) => { if (color !== null) { return color; } else { return '#004070'; }}};
    background-position: center;
    Background-size: contain;
    position: relative;
    border-radius: 15px;

    @media (max-width: 1200px) {
        max-height: 470px;
        min-height: 470px;
    }
`;

export const TextPq = styled.p`
    color: ${({ cor }) => { if (cor === "primary") { return '#004070'; } else { return '#FFFFFF'; }}};
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.3; 
    max-height: 80px;     
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
`;

export const BotoesAudio = styled.div`
    display: grid;
    height: 50px;
    bottom: 0;
    width: 100%;
    margin-top: -105px;

    @media (max-width: 1200px) {
        margin-top: -35px;
    } 
`;

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: 100px;

    @media (max-width: 1520px) {
        height: 100px;
        align-items: center;
    }

    @media (max-width: 1200px) {
        height: 50px;
    }

    @media (max-width: 1000px) {
        height: 100px;
    }

    @media (max-width: 768px) {
        height: 50px;
        margin-top: 50px;
    }
    
    @media (max-width: 400px) {
        height: 50px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
`;

export const TitleBody = styled.div`
    font-size: 30px;
    font-weight: bold;
    color: #004070;
    margin-left: 15px;
    height: 50px;
`;