import React, { useState } from 'react';
import * as S from './styles';
import Logo from '../../assets/img/logo_amarelo.png';
import Face from '../../assets/svg/Face';
import Insta from '../../assets/svg/Insta';
import Twitter from '../../assets/svg/Twitter';
import Whats from '../../assets/svg/Whats';
import Lupa from '../../assets/svg/Lupa';
import Icon from '../../assets/svg/Hamburguer';
import X from '../../assets/svg/X';
import Background from '../../assets/img/banner.png';
import LogoPq from '../../assets/img/logo_pq.png';
import { Container, Row, Col } from 'reactstrap';

const Header = (props) => {
    const [search, setSearch] = useState([]);
    const [open, setOpen] = useState(false);

    return (
        <>
            <S.Container>
                <S.Left>
                    <S.Hamburguer onClick={() => setOpen(!open)}>
                       <Icon />
                    </S.Hamburguer>
                </S.Left>
                <S.DivCenter to="/">
                    <img src={Logo} alt="" />
                </S.DivCenter>
                <S.Right>
                    <S.RedeSociais>
                        <S.Text>Siga o Sindicaixa nas redes sociais.</S.Text>
                        <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sindicaixa.sindicato/`}>
                            <Face />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/sindicaixa/`}>
                            <Insta />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://twitter.com/Sindicaixa`}>
                            <Twitter />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=5551998784504`}>
                            <Whats />
                        </a>
                    </S.RedeSociais>
                    <S.DivSearch>
                        <Lupa style={{ marginTop: "5px", marginLeft: "10px" }} />
                        <S.CustomInputText
                            id="search"
                            type="text"
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                        <S.DivLink to={"/noticias?busca="+search}>
                            <S.ButtonSearch>Buscar</S.ButtonSearch>
                        </S.DivLink>
                    </S.DivSearch>
                </S.Right>
            </S.Container>
            <S.DivBusca>
                <S.DivSearch2>
                    <Lupa style={{ marginTop: "5px", marginLeft: "10px" }} />
                    <S.CustomInputText
                        id="search"
                        type="text"
                        name="search"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <S.DivLink to={"/noticias?busca="+search}>
                            <S.ButtonSearch>Buscar</S.ButtonSearch>
                        </S.DivLink>
                </S.DivSearch2>
                <S.DivRedesSociais>
                    <S.Text style={{ marginTop: "10px" }}>Siga o Sindicaixa nas redes sociais.</S.Text>
                    <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sindicaixa.sindicato/`}>
                        <Face />
                    </a>
                    <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/sindicaixa/`}>
                        <Insta />
                    </a>
                    <a target="_blank" rel="noreferrer" href={`https://twitter.com/Sindicaixa`}>
                        <Twitter />
                    </a>
                    <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=5551998784504`}>
                        <Whats />
                    </a>
                </S.DivRedesSociais>
            </S.DivBusca>
            <S.MenuMobile open={open}>
                <S.HeaderMenu>
                    <S.X>
                        <X onClick={() => setOpen(!open)} />
                    </S.X>
                    <S.Logo>
                        <img src={Logo} alt="" width={"142px"} height={"66px"} /> 
                    </S.Logo>
                    <S.Divider />
                </S.HeaderMenu>
                <S.BodyMenu>
                    <S.NavLink to="/" >HOME</S.NavLink>
                    <S.NavLink to="/sobre" >SOBRE</S.NavLink>
                    <S.NavLink to="/sedes" >SEDES</S.NavLink>
                    <S.NavLink to="/noticias" >NOTÍCIAS</S.NavLink>
                    <S.NavLink to="/multimidia" >MULTIMÍDIA</S.NavLink>
                    <S.NavLink to="/links" >LINKS</S.NavLink>
                    <S.NavLink to="/contato" >CONTATO</S.NavLink>
                    <S.Divider />
                </S.BodyMenu>
                <S.FooterMenu>
                    <S.Text>Siga o Sindicaixa nas redes sociais.</S.Text>
                    <S.RedeSociaisMobile>
                        <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sindicaixa.sindicato/`}>
                            <Face />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/sindicaixa/`}>
                            <Insta />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://twitter.com/Sindicaixa`}>
                            <Twitter />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=5551998784504`}>
                            <Whats />
                        </a>
                    </S.RedeSociaisMobile>
                    <S.DivSearchMenu>
                        <Lupa style={{ marginTop: "5px", marginLeft: "10px" }} />
                        <S.CustomInputText
                            id="search"
                            type="text"
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                            placeholder="Busca:"
                        />
                    </S.DivSearchMenu>
                    <S.Contato>
                        <S.DivTelefone>
                            <S.CustomTelefone />
                            <S.TextContatoTelefone>
                                51 32248049
                            </S.TextContatoTelefone>
                        </S.DivTelefone>
                        <S.DivEmail>
                            <S.CustomEmail />
                            <S.TextContatoEmail>
                                sindicaixa@sindicaixars.org.br
                            </S.TextContatoEmail>
                        </S.DivEmail>
                    </S.Contato>
                </S.FooterMenu>
            </S.MenuMobile>
            <S.BorderGradient />
            <S.ContainerMenu>
                <S.NavLink to="/" >HOME</S.NavLink>
                <S.NavLink to="/sobre" >SOBRE</S.NavLink>
                <S.NavLink to="/sedes" >SEDES</S.NavLink>
                <S.NavLink to="/noticias" >NOTÍCIAS</S.NavLink>
                <S.NavLink to="/multimidia" >MULTIMÍDIA</S.NavLink>
                <S.NavLink to="/links" >LINKS</S.NavLink>
                <S.NavLink to="/contato" >CONTATO</S.NavLink>
            </S.ContainerMenu>
            <S.BorderGradient />
            <S.DivImagem min={props.min}>
                <img src={Background} alt="" width={"100%"} height={"500px"} />
            </S.DivImagem>
            <Container style={{ maxWidth: "94%"}}>
                <Row>
                    <Col md={12}>
                        <S.DivTitulos>
                            <img src={LogoPq} alt="" />
                            <S.TitleBody>{props.name}</S.TitleBody>
                        </S.DivTitulos>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Header;