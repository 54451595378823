import React  from 'react';
import * as S from './styled';
import FaceLinks from '../../assets/svg/FaceLinks';
import InstaLinks from '../../assets/svg/InstaLinks';
import TwitterLinks from '../../assets/svg/TwitterLinks';
import WhatsLinks from '../../assets/svg/WhatsLinks';
import SiteLinks from '../../assets/svg/SiteLinks';

const CardLinks = (props) => {

    return (
        <S.Container>
            <S.Juntar>
                <S.Body picture={props.imagem}>
                    {/* <img src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${props.imagem}`} alt="" width="250px" height="200px"/> */}
                </S.Body>
                <S.Title tamanho={props.nome.length}>{props.nome}</S.Title>
            </S.Juntar>
            <S.RedeSocial>
                {props.face === null ? "" : <a target="_blank" rel="noreferrer" href={`http://${props.face}`}><FaceLinks /></a>}
                {props.insta === null ? "" : <a target="_blank" rel="noreferrer" href={`http://${props.insta}`}><InstaLinks /></a>}
                {props.twitter === null ? "" : <a target="_blank" rel="noreferrer" href={`http://${props.twitter}`}><TwitterLinks /></a>}
                {props.whats === null ? "" : <a target="_blank" rel="noreferrer" href={`http://${props.whats}`}><WhatsLinks /></a>}
                {props.site === null ? "" : <a target="_blank" rel="noreferrer" href={`http://${props.site}`}><SiteLinks /></a>}
            </S.RedeSocial>
            {/* <S.Text>{props.texto}</S.Text> */}
        </S.Container> 
    )
}

export default CardLinks;