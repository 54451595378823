import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import CardLinks from '../../components/CardLinks';
import Axios from '../../utils/Axios';

const Links = () => {
  const [dados, setDados] = useState([]);

  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/links`).then((response) => {
    setDados(response.data)
    })
  }, []);

  return (
    <>
      <Header min="true" name="Links"/>
      <Container style={{ maxWidth: "94%"}}>
        <Row style={{ marginTop: "100px" }}>
          {dados !== undefined && dados.map((item, index) => (
            <Col xl={3} lg={4} sm={6} key={index}>
              <CardLinks texto={item.texto} nome={item.nome} face={item.face} insta={item.insta} twitter={item.twitter} site={item.site} whats={item.whats} imagem={item.imagem} />
            </Col>
          ))}
        </Row>
      </Container>  
      <Footer />
      <Assinatura />
    </>
  );
};

export default Links;