import React from 'react';
import * as S from './styled';
import Carregando from '../../assets/gif/Loading.gif';

const Loading = () => {
    return (
        <S.Container>
            <img src={Carregando} alt="" />
        </S.Container>
    );
};

export default Loading;