import styled from 'styled-components';
import Email from '../../assets/svg/Email';
import Telefone from '../../assets/svg/Telefone';
import InputMask from "react-input-mask";

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: 22px;
`;

export const TitleBody = styled.div`
    font-size: 30px;
    font-weight: bold;
    color: ${({ cor }) => { if (cor === "secundary") { return '#004070'; } else { return '#FFFFFF'; }}};
    height: 50px;

    @media (max-width: 450px) {
        font-size: 25px;
    }
`;

export const TextBody = styled.text`
    font-size: 18px;
    color: #707070;
`;

export const TitleBodyBlue = styled.text`
    font-size: 35px;
    color: #004070;
    font-weight: bold;
    margin-left: 5px;

    @media (max-width: 515px) {
        font-size: 30px;
    }
    @media (max-width: 457px) {
        font-size: 25px;
    }
`;

export const Fundo = styled.div`
    height: 755px;
    width: 50%;
    background-color: #004070;
    border-radius: 15px 0 15px 0;
    padding-top: 50px;
    padding-left: 20px;

    @media (max-width: 1200px) {
        width: 100%;
        border-radius: 15px 0 15px 0;
        margin-top: -5px;
    }

    @media (max-width: 450px) {
        width: 95%;
    }
`;

export const CustomEmail = styled(Email)`
    & path {
        fill: #7BC7FF;
    }
`;

export const CustomTelefone = styled(Telefone)`
    & path {
        fill: #7BC7FF;
    }
`;

export const TextContato = styled.text`
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 600;

    @media (max-width: 500px) {
        font-size: 20px;
    }
`;

export const TextGrContato = styled.text`
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 600;
    white-space: nowrap;
`;

export const TextPqContato = styled.text`
    color: #FFFFFF;
    font-size: 15px;
`;
export const TextPqContatoBlue = styled.text`
    color: #004070;
    font-size: 15px;
    margin-left: 60px;
`;

export const FundoGrid = styled.div`
    display: grid;
    justify-content: start;
`;

export const BodyInfo = styled.div`
    display: grid;
`;

export const CustomInputText = styled(InputMask)`
    border: 0px;
    width: 90%;
    height: 35px;
    margin-left: 5px;
    margin-top: 20px;
    background: #FFFFFF;
    border-radius: 15px 0 15px 0;
    padding-left: 5px;

    &::-webkit-input-placeholder {
        color: #CBCBCB;
        padding-left: 5px;
    }

    &:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
        border: none;
    }
`;

export const DivInfos = styled.div`
    display: flex;
`;

export const DivInput = styled.div`
    width: 100%;
    padding-left: 60px;
`;

export const DivMaps = styled.div`
    width: 50%;
    margin-left: 20px;

    @media (max-width: 1200px) {
        width: 100%;
        margin-left: 0;
    }

    @media (max-width: 450px) {
        width: 95%;
        margin-left: 0;
    }
`;

export const DivDestaque = styled.div`
    display: flex;
    margin-top: 100px;

    @media (max-width: 1200px) {
        display: grid;
    }
`;

export const TextRight = styled.div`
    font-size: 18px;
    color: #000000;
    margin-top: 50px;

    @media (max-width: 1200px) {
        margin-top: 0px;
    }
`;

export const Conteiner = styled.div`
    display: flex;

    @media (max-width: 1200px) {
        display: grid;
    }
`;
    
export const Right = styled.div`
    max-width: 100%;
`;

export const DivButton = styled.div`

`;