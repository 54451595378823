import styled from 'styled-components';
import Maps from '../../assets/svg/Maps';

export const DivMaps = styled.div`
    display: flex;
    justify-content: end;
`;

export const CustomMaps = styled(Maps)`
    position: relative; 
    z-index: 99;
    margin-top: -407px;
    margin-right: -1px;
`;

export const Title = styled.text`
    font-size: 35px;
    font-weight: bold;
    color: #FFFFFF;

    @media (max-width: 850px) {
        font-size: 30px;
    } 
`;

export const Text = styled.div`
    font-size: 15px;
    color: #FFFFFF;

    @media (min-width: 768px) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;     /* fallback */
        max-height: 320px;      /* fallback */
        -webkit-line-clamp: 10; /* number of lines to show */
        -webkit-box-orient: vertical;
    }  
`;

export const Right = styled.div`
    display: grid;
    width: 100%;
    height: 400px;
    background-color: #004070;
    border-radius: 0 15px 15px 0;
`;

export const DivEnd = styled.div`
    display: flex;
    height: 65px;
    margin-top: 22px;
    margin-left: 30px;
`;

export const End = styled.div`
    display: grid;
    margin-left: 15px;
`;

export const DivText = styled.div`
    margin: 30px 30px 0 30px;
    display: grid;

    @media (max-width: 1300px) {
        margin: 10px 30px 0 30px;
    } 

    @media (max-width: 900px) {
        margin: 10px 30px 0 10px;
    }
`;

export const TextNormal = styled.text`
    font-size: 13px;
    color: #FFFFFF;
`;

export const TextBold = styled.text`
    font-size: 13px;
    font-weight: bold;
    color: #FFFFFF;
`;

export const Desktop = styled.div`
    @media (min-width: 768px) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;     /* fallback */
        max-height: 320px;      /* fallback */
        -webkit-line-clamp: 10; /* number of lines to show */
        -webkit-box-orient: vertical;
    }  
`;

export const Text2 = styled.div`
    height: 170px;
`;