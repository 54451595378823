import React, { useState, useRef } from 'react';
import song from '../../assets/audio/Creed - One Last Breath (Video 2009).mp3';
import Slider from '../Slider';
import ControlPanel from '../ControlPanel';
import * as S from './styles';
// import FrequenciaAzulClaro from '../../assets/img/TABLET_FREQUENCIA_AZUL_CLARO.png';

const Audio = (props) => {
    const [percentage, setPercentage] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    const audioRef = useRef()

    const onChange = (e) => {
        const audio = audioRef.current
        audio.currentTime = (audio.duration / 100) * e.target.value
        setPercentage(e.target.value)
    }

    const play = () => {
        const audio = audioRef.current
        audio.volume = 0.1

        if (!isPlaying) {
        setIsPlaying(true)
        audio.play()
        }

        if (isPlaying) {
        setIsPlaying(false)
        audio.pause()
        }
    }

    const getCurrDuration = (e) => {
        const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
        const time = e.currentTarget.currentTime
    
        setPercentage(+percent)
        setCurrentTime(time.toFixed(2))
    }

    return (
      <S.Container cor={props.cor}>
        <Slider percentage={percentage} onChange={onChange} size={props.size} cor={props.cor} />
        <audio
          ref={audioRef}
          onTimeUpdate={getCurrDuration}
          onLoadedData={(e) => {
            setDuration(e.currentTarget.duration.toFixed(2))
          }}
          src={song}
        ></audio>
        
        {/* {props?.size === "medio" && props?.cor === "azul_claro" ? 
          <img src={FrequenciaAzulClaro} alt="" height="132px" width="300px" style={{ marginTop: "-30px", zIndex: "2", position: "absolute" }}/> 
        : 
        props?.size  === "medio" ? <S.CustomFrequencia /> : <S.CustomFrequenciaGr />} */}
        <ControlPanel
          play={play}
          isPlaying={isPlaying}
          duration={duration}
          currentTime={currentTime}
          size={props.size}
          cor={props.cor}
        />
      </S.Container>
    );
};

export default Audio;