import React from 'react';
import * as S from './styled';
import X from '../../assets/svg/X';
import InstaAzul from '../../assets/svg/InstaAzul';
import FaceAzulSemCirculo from '../../assets/svg/FaceAzulSemCirculo';

const ModalFull = ({ modal, setModal, modalEdit, setModalEdit, deleteUser, setDeleteUser, acao, retorno, setRetorno, face, insta }) => {

    function handleModal () {
        setModal(false);
    };

    function handleModalEdit () {
        setModalEdit(false);
    };

    return (
        <S.CustomModal>
            <S.DivModal>
                <S.HeaderModal>
                    <S.Left />
                    <S.Right onClick={acao === "editUser" ? handleModalEdit : handleModal}>
                        <X />
                    </S.Right>
                </S.HeaderModal>
                <S.ModalBody>
                    <S.TextWhite>Você deseja visualizar a notícia em qual rede social?</S.TextWhite>
                    <S.Redes>
                        <a target="_blank" rel="noreferrer" href={face}>
                            <S.Face><FaceAzulSemCirculo size='big' /></S.Face>
                        </a>
                        <a target="_blank" rel="noreferrer" href={insta}>
                            <S.Insta><InstaAzul width="20px" /></S.Insta>
                        </a>
                    </S.Redes>
                </S.ModalBody>
            </S.DivModal>
        </S.CustomModal>
    )
}

export default ModalFull;