import React  from 'react';
import * as S from './styled';
import LinhaTempo from '../../assets/svg/LinhaTempo';

const CardHistoria = (props) => {
    return (
        <S.Container>
            <S.Title>{props.data}</S.Title>
            <S.Text>{props.texto}</S.Text>
            <S.Imagem>
                <LinhaTempo />
            </S.Imagem>
            <S.Center>
                <S.Ano>{props.ano}</S.Ano>
            </S.Center>    
        </S.Container>
    )
}

export default CardHistoria;