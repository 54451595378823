import styled from 'styled-components';

export const Fundo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  flex-grow: 1;
`;

export const Play = styled.div`
  width: 70px;
  height: 70px;
  cursor: pointer;
  background-color: #7BC7FF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  margin-top: ${({ size }) => { if (size === "medio") { return '-125px;'; } else { return '-85px;'; }}};
  margin-left: 30px;
`;

export const Stop = styled.div`
  height: 70px;
  width: 70px;
  cursor: pointer;
  background-color: #7BC7FF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  margin-top: ${({ size }) => { if (size === "medio") { return '-125px;'; } else { return '-85px;'; }}};
  margin-left: 30px;
`;

export const Container = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
`;

export const Timer = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${({ cor }) => { if (cor === "azul_claro") { return '#004070'; } else { return '#7BC7FF;'; }}};
  margin-top: -130px;
`;

export const DivButton = styled.div`
  height: 90px;
  margin-top: ${({ cor }) => { if (cor === "branco") { return '30px'; } else { return '0'; }}};
`;