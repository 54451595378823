import React from 'react';

function Marcacao(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="21" viewBox="0 0 41 21" fill="none" {...props}>
            <path id="Polígono_8" data-name="Polígono 8" d="M20.5,0,41,21H0Z" transform="translate(41 21) rotate(180)" fill="#004070"/>
        </svg>
    )
}

export default Marcacao;