import React from 'react';

function AMais(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.181" height="29.06" viewBox="0 0 29.181 29.06" fill="none" {...props}>
            <g id="Grupo_3175" data-name="Grupo 3175" transform="translate(-8270.373 -6233.582)">
                <path id="Caminho_449" data-name="Caminho 449" d="M8284.58,6242.913q-.34,1.314-1.942,6.5l3.914.007q-1.509-4.86-1.7-5.5C8284.726,6243.5,8284.635,6243.159,8284.58,6242.913Z" fill="#b3b1b1"/>
                <path id="Caminho_450" data-name="Caminho 450" d="M8285.146,6233.584h-.243a14.645,14.645,0,1,0,.243,0Zm3.257,22.085-1.09-3.577h-5.479l-1.088,3.577h-3.433l5.3-15.087h3.9l5.323,15.087Zm4.607-16.324h-1.446v1.447a.543.543,0,1,1-1.086,0v-1.447h-1.445a.543.543,0,1,1,0-1.085h1.445v-1.448a.543.543,0,0,1,1.086,0v1.448h1.446a.543.543,0,0,1,0,1.085Z" fill="#b3b1b1"/>
            </g>
        </svg>
    )
}

export default AMais;