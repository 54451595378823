import React from 'react';
import * as S from './styled';
import X from '../../assets/svg/X';
import Check from '../../assets/svg/Check';

const Modal = ({ modal, setModal, modalEdit, setModalEdit, deleteUser, setDeleteUser, acao, retorno, setRetorno }) => {

    function handleModal () {
        setModal(false);
    };

    function handleModalEdit () {
        setModalEdit(false);
    };

    function handleDelete () {
        setDeleteUser(!deleteUser);
    };

    return (
        <S.CustomModal>
            <S.DivModal>
                <S.HeaderModal>
                    <S.Left>
                        <S.CustomAdv color={"branco"} />
                        <S.TextWhite>{acao === "news" ? "CADASTRO - NEWSLETTER" : "MENSAGEM - CONTATO"}</S.TextWhite>
                    </S.Left>
                    <S.Right onClick={acao === "editUser" ? handleModalEdit : handleModal}>
                        <X />
                    </S.Right>
                </S.HeaderModal>
                <S.ModalBody>
                    {acao === "news" ?
                        <S.CenterGrid>
                            {retorno === true ?
                            <>
                                <S.Center>
                                    <Check />
                                </S.Center>
                                <S.TextGreen>Cadastro realizado com sucesso</S.TextGreen>
                            </>
                            :
                                <>
                                    carregando...
                                </>
                            }

                        </S.CenterGrid>
                    : acao === "contato" ?
                        <S.CenterGrid>
                            {retorno === true ?
                            <>
                                <S.Center>
                                    <Check />
                                </S.Center>
                                <S.TextGreen>Mensagem enviada com sucesso</S.TextGreen>
                            </>
                            :
                                <>
                                    carregando...
                                </>
                            }

                        </S.CenterGrid>
                    : acao === "confirmAddUser" ?
                        <S.CenterGrid>
                            {retorno === true ?
                            <>
                                <S.Center>
                                    <Check />
                                </S.Center>
                                <S.TextGreen>Notícia adicionada com sucesso</S.TextGreen>
                            </>
                            :
                                <>
                                    <S.TextBlue>Tem certeza que deseja excluir do sindicalizado?</S.TextBlue>
                                    <S.DivButton>
                                        <S.ButtonConfirma onClick={handleDelete}>
                                        </S.ButtonConfirma>
                                        <S.ButtonCancela onClick={handleModal} >
                                        </S.ButtonCancela>
                                    </S.DivButton>
                                </>
                            }
                        </S.CenterGrid>
                    : acao === "editUser" ?
                        <S.CenterGrid>
                            <S.Center>
                                <Check />
                            </S.Center>
                            <S.TextGreen>Sindicalizado editado com sucesso</S.TextGreen>
                        </S.CenterGrid>
                    :
                        <>
                            <S.BodyLeft style={{ paddingTop: "30px"}}>
                                <S.DivIcone>
                                    <S.CustomAdv color={"cinza"} />
                                </S.DivIcone>
                                <S.Body>
                                    <S.CardText>
                                        <S.TagTitleNext>Processo</S.TagTitleNext>
                                        <S.TitleCardNext>Campanha salarial 2021</S.TitleCardNext>
                                        <S.TagTitleNext>Numero do Processo</S.TagTitleNext>
                                        <S.TitleCardNext>Campanha salarial 2021</S.TitleCardNext>
                                    </S.CardText>
                                </S.Body>
                            </S.BodyLeft>
                            <S.Center style={{ marginTop: "30px", display: "grid"}}>
                                <S.CustomLabel for="exampleText">Pergunta</S.CustomLabel>
                                <S.CustomFormGroup>
                                    <S.CustomInput type="textarea" name="text" style={{ height: "130px" }} />
                                </S.CustomFormGroup>
                            </S.Center>
                            <S.DivRight onClick={() => setModal(!modal)}>
                            </S.DivRight>
                        </>
                    }
                </S.ModalBody>
            </S.DivModal>
        </S.CustomModal>
    )
}

export default Modal;