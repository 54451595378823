import React from 'react';

function Telefone(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38.998" height="39" viewBox="0 0 38.998 39" fill="none" {...props}>
            <path id="Caminho_76" data-name="Caminho 76" d="M1093.56,513.134h0m.034,0h0m-9.569,8.554-14.607-14.644a11.917,11.917,0,0,1,0-16.809c.369-.37.737-.428.808-.357l7.593,7.612a6.556,6.556,0,0,1-.167.695c-.216.751-.6,1.843-1.162,3.267a4.9,4.9,0,0,0,.573,5.616l6.8,6.82c1.383,1.386,3.64,1.564,5.779.56,1.359-.533,2.445-.921,3.19-1.136.253-.073.46-.124.615-.153l7.563,7.582c.106.107.034.558-.356.949a11.727,11.727,0,0,1-16.63,0M1068,488.953a13.711,13.711,0,0,0,0,19.368l14.607,14.643a13.745,13.745,0,0,0,19.457,0,2.5,2.5,0,0,0,.356-3.506l-7.82-7.839c-.719-.721-1.979-.358-5.766,1.134-1.488.7-2.824.591-3.562-.148l-6.8-6.819c-.76-.762-.87-2.163-.182-3.635,1.48-3.77,1.844-5.036,1.166-5.716l-7.82-7.839a2.055,2.055,0,0,0-1.486-.6,3.114,3.114,0,0,0-2.149.953" transform="translate(-1064 -488)" fill="#fff"/>
        </svg>
    )
}

export default Telefone;