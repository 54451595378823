import React from 'react';

function Twitter(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="39.062" height="39.061" viewBox="0 0 39.062 39.061" fill="none" {...props}>
            <g id="Grupo_10" data-name="Grupo 10" transform="translate(0.5 0.5)">
                <path id="Caminho_55" data-name="Caminho 55" d="M110.022,38.561h0a19.031,19.031,0,0,1-19.031-19.03h0a19.031,19.031,0,0,1,38.062,0h0A19.031,19.031,0,0,1,110.022,38.561Z" transform="translate(-90.991 -0.5)" fill="none" stroke="#7bc7ff" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Caminho_62" data-name="Caminho 62" d="M99.468,26.161a12.315,12.315,0,0,0,18.946-10.936,8.788,8.788,0,0,0,2.161-2.241,8.659,8.659,0,0,1-2.487.681,4.337,4.337,0,0,0,1.9-2.395,8.673,8.673,0,0,1-2.749,1.051,4.333,4.333,0,0,0-7.379,3.95,12.293,12.293,0,0,1-8.925-4.525,4.335,4.335,0,0,0,1.34,5.782,4.323,4.323,0,0,1-1.962-.542,4.336,4.336,0,0,0,3.473,4.3,4.344,4.344,0,0,1-1.955.074,4.332,4.332,0,0,0,4.045,3.007,8.706,8.706,0,0,1-6.411,1.793" transform="translate(-90.991 -0.5)" fill="#7bc7ff"/>
            </g>
        </svg>
    )
}

export default Twitter;