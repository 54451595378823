import styled from 'styled-components';
import Frequencia from '../../assets/svg/Frequencia';
import FrequenciaGr from '../../assets/svg/FrequenciaGr';

export const CustomFrequencia = styled(Frequencia)`
    position: absolute;
    z-index: 2;
    margin-top: 75px;
    height: 300px;
    width: 300px;
`;

export const Container = styled.div`
    margin-top: 40px;
    height: ${({ cor }) => { if (cor === "branco") { return '150px'; } else { return '300px'; }}};
    display: grid;
    aling-items: center;
`;

export const CustomFrequenciaGr = styled(FrequenciaGr)`
    position: absolute;
    z-index: 2;
    margin-top: -15px;
`;