import React from 'react';
import * as S from './styles';
import logoAlternativa from '../../assets/img/alternativa.png';

const Footer = () => {
    return (
        <S.Fundo href="http://alternativadigital.com.br/">
            SITE DESENVOLVIDO POR
            <img src={logoAlternativa} alt="" width={"166px"} height={"46px"} style={{ marginLeft: "10px" }} />
        </S.Fundo>
    );
};

export default Footer;