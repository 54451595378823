import styled from 'styled-components';

export const Fundo = styled.a`
    display: flex;
    justify-content: center;
    background-color: #004070;
    align-items: center;
    height: 80px;
    color: #FFFFFF;
    text-decoration: none;
    border-top: 1px solid #F27C00;

    &:hover {
        color: #FFFFFF;
    }
`;