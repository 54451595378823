import React from 'react';
import * as S from './styles';
import { Col } from 'reactstrap';
import Tag from '../../assets/img/tag.png';

const Maps = (props) => {
    
    return (
        <>
            <Col md={6}>
                <iframe
                    src={props.google}
                    width="100%"
                    height="400px"
                    frameBorder="0"
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                    title="maps"
                    style={{ marginLeft: "20px"}}
                />
                <S.DivMaps>
                    <S.CustomMaps />
                </S.DivMaps>
            </Col>
            <Col md={6}>
                <S.Right>
                    <S.DivText>
                        <S.Title>{props.nome}</S.Title>
                        {props.texto !== null ?
                            <S.Text>{props.texto}</S.Text>
                            : <S.Text2 />         
                        }
                    </S.DivText>
                    <S.DivEnd>
                        <img src={Tag} alt="" />
                        <S.End>
                            <S.TextBold>{props.endereco}</S.TextBold>
                            <S.TextNormal>{props.bairro} - {props.cidade}</S.TextNormal>
                            <S.TextNormal>CEP: {props.cep}</S.TextNormal>
                        </S.End>
                    </S.DivEnd>
                </S.Right>
            </Col>
        </>
    )
}

export default Maps;