import styled from 'styled-components';

export const CustonButtonPrimary = styled.button`
    background-color: ${({ cor }) => { if (cor === "secundary") { return '#7BC7FF'; } else { return '#0777C9'; }}};
    color: #FFFFFF;
    border-radius: ${({ efeito }) => { if (efeito === "true") { return '15px 0 15px 0'; } else { return '15px'; }}};
    border: 0px;

    &:hover {
        background-color: #FFFFFF;
        color: #0777C9;
    }
`;

export const TextButton = styled.text`
    font-size: 15px;
`;