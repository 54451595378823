import React, { useState } from 'react';
import * as S from './styles';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import LogoPq from '../../assets/img/logo_pq.png';
import ButtonPrimary from '../../components/ButtonPrimary';
import validator from 'validator';
import Axios from '../../utils/Axios';
import Modal from '../../components/Modal';

const Contato = () => {
  const googleContato = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.9919348622293!2d-51.223155784278575!3d-30.037089237896257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951979aa9f34e7bf%3A0xd7a390e372fa9735!2sR.%20da%20Rep%C3%BAblica%2C%2092%20-%20Cidade%20Baixa%2C%20Porto%20Alegre%20-%20RS%2C%2090050-320!5e0!3m2!1spt-BR!2sbr!4v1655746652260!5m2!1spt-BR!2sbr";
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [assunto, setAssunto] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [erro, setEmailError] = useState("");
  const [modal, setModal] = useState(false);
  const [retorno, setRetorno] = useState(false);

  const validateEmail = (e) => {
    var email = e.target.value
  
    if (validator.isEmail(email)) {
      setEmailError('#00FF00')
    } else {
      setEmailError('#FF0000')
    }
  }

  const handleContato = async () => {
    setModal(true);
    await Axios.post(`/mensagens`,{
        nome: nome,
        email: email,
        telefone: telefone,
        assunto: assunto,
        mensagem: mensagem,
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setRetorno(true);
      setNome("");
      setEmail("");
      setTelefone("");
      setAssunto("");
      setMensagem("");
    })
};
  
  return (
    <>
      {modal && 
        <Modal
          modal={modal} setModal={setModal} 
          acao="contato" 
          retorno={retorno}
        />
      }
      <Header min="true" name="Contato"/>
      <Container style={{ maxWidth: "94%"}}>
        <S.DivDestaque>
          <S.DivMaps>
            <iframe
              src={googleContato}
              width="100%"
              height="755px"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="maps"
            />
          </S.DivMaps>
          <S.Fundo>
            <S.DivInfos>
              <S.CustomTelefone style={{ marginRight: "15px" }} />
              <S.TextGrContato>51 32248049</S.TextGrContato>
            </S.DivInfos>
            <S.DivInfos style={{ marginTop: "25px" }}>
              <S.CustomEmail style={{ marginRight: "10px" }} />
              <S.TextContato>sindicaixa@sindicaixars.org.br</S.TextContato>
            </S.DivInfos>
            <S.DivInfos style={{ marginTop: "25px" }}>
              <img src={LogoPq} alt="" style={{ marginRight: "10px" }}  />
              <S.TitleBody>Sede Administrativa</S.TitleBody>
            </S.DivInfos>
            <S.DivInfos>
              <S.FundoGrid style={{ marginLeft: "60px" }}>
                <S.TextPqContato>Rua da República nº 92</S.TextPqContato>
                <S.TextPqContato>Cidade Baixa - Porto Alegre - RS</S.TextPqContato>
                <S.TextPqContato>CEP: 90050-320</S.TextPqContato>
              </S.FundoGrid>
            </S.DivInfos>
            <S.DivInput>
              <S.BodyInfo>
                <S.CustomInputText
                  id="nome"
                  type="text"
                  name="nome"
                  onChange={(e) => setNome(e.target.value)}
                  value={nome}
                  placeholder="Nome"
                />
                <S.CustomInputText
                  id="email"
                  type="text"
                  name="email"
                  onChange={(e) => {validateEmail(e);setEmail(e.target.value)}}
                  value={email}
                  placeholder="E-mail"
                  style={{ border: `3px solid ${!erro ? "#00000000" : erro}`}}
                />
                <S.CustomInputText
                  id="telefone"
                  type="text"
                  name="telefone"
                  onChange={(e) => setTelefone(e.target.value)}
                  mask="(99) 99999-9999"
                  value={telefone}
                  placeholder="Telefone"
                />
                <S.CustomInputText
                  id="assunto"
                  type="text"
                  name="assunto"
                  onChange={(e) => setAssunto(e.target.value)}
                  value={assunto}
                  placeholder="Assunto"
                />
                <S.CustomInputText
                  id="mensagem"
                  type="textarea"
                  name="mensagem"
                  onChange={(e) => setMensagem(e.target.value)}
                  value={mensagem}
                  placeholder="Mensagem"
                  style={{ marginBottom: "20px", height: 127, maxWidth: "100%" }}
                />
                <S.DivButton onClick={() => handleContato()}>
                  <ButtonPrimary label="Enviar mensagem" size="90%" cor="secundary" efeito="true" />
                </S.DivButton>
              </S.BodyInfo>  
            </S.DivInput>
          </S.Fundo>
        </S.DivDestaque>
        <Row style={{ marginTop: "70px" }}>
          <Col xl={6} md={6} style={{ display: "flex", justifyContent: "center" }}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" />
                <S.TitleBodyBlue>Sede Administrativa</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Rua da República, 92</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Cidade Baixa - Porto Alegre</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 90050-320</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col>
          <Col xl={6} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" />
                <S.TitleBodyBlue>Sede Social</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Rua Manoel Leão, 90</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Pedra Redonda - Porto Alegre</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 900050-320</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col>
        </Row>
        <Row>
          <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" />
                <S.TitleBodyBlue>Regional Alegrete</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Não possui sede - Responsável: Marilene Carvalho</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Para correspondências: Sede Administrativa Porto Alegre</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col>
          {/* <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" />
                <S.TitleBodyBlue>Regional Caxias do Sul</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Rua Hercules Gallo, 570</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Centro - Caxias do Sul</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 95020-330</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col> */}
          <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" />
                <S.TitleBodyBlue>Regional Passo Fundo</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Rua Guilherme Kurte, 815</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Vila Luiza - Passo Fundo</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 99072-445</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col>
          <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" />
                <S.TitleBodyBlue>Regional Pelotas</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Rua Dr. Cassiano, 268</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Centro - Pelotas</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 96015-700</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col>
          {/* <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" />
                <S.TitleBodyBlue>Regional Santa Maria</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Rua Dr. Alberto Pasqualini, 70, Sala 808</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Centro - Santa Maria</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 97015-001</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col> */}
          <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" />
                <S.TitleBodyBlue>Regional Santo Ângelo</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Não possui sede - Responsável: Tania Tosi</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Para correspondências: Rua Honduras, 773</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Jardim Res. Sabo - Santo Ângelo</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 98804-180</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col>
        </Row>
      </Container>  
      <Footer />
      <Assinatura />
    </>
  );
};

export default Contato;