import React from 'react';

function Maps(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="273" height="400" viewBox="0 0 273 400" fill="none" {...props}>
            <defs>
                <clipPath id="clip-path">
                <rect id="Retângulo_1957" data-name="Retângulo 1957" width="273" height="400" transform="translate(679 3112)" fill="#fff" stroke="#707070" strokeWidth="1"/>
                </clipPath>
                <clipPath id="clip-path-2">
                <rect id="Retângulo_1955" data-name="Retângulo 1955" width="266.42" height="400.001" fill="#004070"/>
                </clipPath>
            </defs>
            <g id="Grupo_de_máscara_28" data-name="Grupo de máscara 28" transform="translate(-679 -3112)" clipPath="url(#clip-path)">
                <g id="Grupo_2832" data-name="Grupo 2832" transform="translate(818.79 3111.999)">
                <g id="Grupo_2808" data-name="Grupo 2808" clipPath="url(#clip-path-2)">
                    <path id="Caminho_414" data-name="Caminho 414" d="M133.283,400c-6.253-11.121-11.841-21.271-17.625-31.31C82.745,311.56,49.62,254.551,16.882,197.321c-43.709-76.41,1.644-176.454,87.9-194.366,73.063-15.173,142.567,29.477,158.434,102.386,7.045,32.377,2.505,63.561-14.037,92.479Q194.259,293.83,138.923,389.6c-1.8,3.122-3.439,6.331-5.639,10.4m-.056-147.389A119.521,119.521,0,0,0,252.921,133.288c.065-66.328-53.452-120.14-119.52-120.179C67.416,13.071,13.487,66.678,13.439,132.358c-.049,66.777,53.2,120.233,119.789,120.255" transform="translate(0 0)" fill="#004070"/>
                </g>
                </g>
            </g>
        </svg>
    )
}

export default Maps;