import React from 'react';

function InstaLinks(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="39.062" height="39.061" viewBox="0 0 39.062 39.061" fill="none" {...props}>
            <g id="Grupo_8" data-name="Grupo 8" transform="translate(0.5 0.5)">
                <path id="Caminho_58" data-name="Caminho 58" d="M62.852,39.061a19.535,19.535,0,0,1-7.6-37.526,19.535,19.535,0,0,1,15.2,35.991A19.409,19.409,0,0,1,62.852,39.061ZM62.852,1A18.53,18.53,0,1,0,81.383,19.531,18.551,18.551,0,0,0,62.852,1Z" transform="translate(-43.821 -0.5)" fill="#004070"/>
                <path id="Caminho_59" data-name="Caminho 59" d="M62.852,10.836c2.728,0,3.051.011,4.128.062a5.475,5.475,0,0,1,1.9.365,3.169,3.169,0,0,1,1.175.793,3.325,3.325,0,0,1,.764,1.22,6.074,6.074,0,0,1,.352,1.969c.049,1.119.059,1.454.059,4.286s-.01,3.167-.059,4.286a6.074,6.074,0,0,1-.352,1.969,3.313,3.313,0,0,1-.764,1.219,3.171,3.171,0,0,1-1.175.794,5.475,5.475,0,0,1-1.9.365c-1.077.051-1.4.062-4.128.062s-3.051-.011-4.128-.062a5.475,5.475,0,0,1-1.9-.365,3.171,3.171,0,0,1-1.175-.794,3.313,3.313,0,0,1-.764-1.219,6.074,6.074,0,0,1-.352-1.969c-.049-1.119-.059-1.454-.059-4.286s.01-3.167.059-4.286a6.074,6.074,0,0,1,.352-1.969,3.325,3.325,0,0,1,.764-1.22,3.169,3.169,0,0,1,1.175-.793,5.475,5.475,0,0,1,1.9-.365c1.077-.051,1.4-.062,4.128-.062m0-1.911c-2.774,0-3.122.012-4.212.064a7.254,7.254,0,0,0-2.48.493,4.984,4.984,0,0,0-1.809,1.223,5.221,5.221,0,0,0-1.178,1.879,8.007,8.007,0,0,0-.475,2.574c-.05,1.131-.062,1.492-.062,4.373s.012,3.241.062,4.373a8.013,8.013,0,0,0,.475,2.574,5.229,5.229,0,0,0,1.178,1.879A5,5,0,0,0,56.16,29.58a7.255,7.255,0,0,0,2.48.493c1.09.052,1.438.064,4.212.064s3.122-.012,4.212-.064a7.254,7.254,0,0,0,2.48-.493,5,5,0,0,0,1.809-1.223,5.246,5.246,0,0,0,1.179-1.879,8.044,8.044,0,0,0,.474-2.574c.05-1.132.062-1.493.062-4.373s-.012-3.242-.062-4.373a8.038,8.038,0,0,0-.474-2.574,5.237,5.237,0,0,0-1.179-1.879,4.992,4.992,0,0,0-1.809-1.223,7.254,7.254,0,0,0-2.48-.493c-1.09-.052-1.437-.064-4.212-.064" transform="translate(-43.821 -0.5)" fill="#004070"/>
                <path id="Caminho_60" data-name="Caminho 60" d="M62.852,14.084a5.35,5.35,0,0,0-5.246,5.447,5.25,5.25,0,1,0,10.492,0,5.35,5.35,0,0,0-5.246-5.447m0,8.982a3.538,3.538,0,1,1,3.405-3.535,3.472,3.472,0,0,1-3.405,3.535" transform="translate(-43.821 -0.5)" fill="#004070"/>
                <path id="Caminho_61" data-name="Caminho 61" d="M69.532,13.869A1.227,1.227,0,1,1,68.305,12.6a1.251,1.251,0,0,1,1.227,1.273" transform="translate(-43.821 -0.5)" fill="#004070"/>
            </g>
        </svg>
    )
}

export default InstaLinks;