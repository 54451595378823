import React from 'react'
import * as S from './styles';
import Pause from '../../assets/img/TABLET_PAUSE_ESCURO.png';
import Play from '../../assets/img/TABLET_PLAY.png';

function Button({ play, isPlaying, size }) {

  return (
    <S.Fundo>
      {isPlaying ? 
        <S.Stop onClick={play} size={size}>
          <img src={Pause} alt="" height="70px" width="70px" style={{ zIndex: "2", position: "absolute" }}/> 
        </S.Stop>
        : 
        <S.Play onClick={play} size={size}>
          <img src={Play} alt="" height="70px" width="70px" style={{ zIndex: "2", position: "absolute" }}/>
        </S.Play>}
    </S.Fundo>
  )
}
export default Button