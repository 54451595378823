import React from 'react';

function Hamburguer(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
            <g id="Grupo_701" data-name="Grupo 701" transform="translate(-347.152 -58.629)">
                <rect id="Retângulo_96" data-name="Retângulo 96" width="20" height="3" rx="1.5" transform="translate(347.152 58.629)" fill="#fcfcfc"/>
                <rect id="Retângulo_97" data-name="Retângulo 97" width="20" height="3" rx="1.5" transform="translate(347.152 64.629)" fill="#fcfcfc"/>
                <rect id="Retângulo_98" data-name="Retângulo 98" width="20" height="3" rx="1.5" transform="translate(347.152 70.629)" fill="#fcfcfc"/>
                <rect id="Retângulo_99" data-name="Retângulo 99" width="20" height="3" rx="1.5" transform="translate(347.152 76.629)" fill="#fcfcfc"/>
            </g>
        </svg>
    )
}

export default Hamburguer;