import React from 'react';
import * as S from './styled';

const ImagemPqSobre = (props) => {

    return (
        <S.Fundo>
            {/* <S.Primeiro>
                <S.Segundo />
            </S.Primeiro> */}
            <S.Nome>{props.nome}</S.Nome>
            <S.Cargo>{props.cargo}</S.Cargo>
        </S.Fundo>
    )
}

export default ImagemPqSobre;