import React from 'react';

function X(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.264" height="16.264" viewBox="0 0 16.264 16.264" fill="none" {...props}>
            <g id="Grupo_2908" data-name="Grupo 2908" transform="translate(-359.868 -17.659)">
                <rect id="Retângulo_101" data-name="Retângulo 101" width="20" height="3" rx="1.5" transform="translate(359.868 31.802) rotate(-45)" fill="#fcfcfc"/>
                <rect id="Retângulo_102" data-name="Retângulo 102" width="20" height="3" rx="1.5" transform="translate(361.99 17.659) rotate(45)" fill="#fcfcfc"/>
            </g>
        </svg>
    )
}

export default X;