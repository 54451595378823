import styled from 'styled-components';

export const Fundo = styled.div`
    background-color: #004070;
    height: 100%;
    margin-top: 100px;

    // @media (max-width: 1200px) {
    //     height: 350px;
    // }

    // @media (max-width: 570px) {
    //     height: 400px;
    // }
`;

export const LeftEstatuto = styled.div`
    display: grid;
    margin-top: 30px;
`;

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: 30px;
`;

export const TitleBody = styled.div`
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 15px;
    height: 50px;
    width: 200px;
`;

export const TitleEstatuto = styled.text`
    font-size: 35px;
    font-weight: bold;
    color: #FFFFFF;
`;

export const TextEstatuto = styled.text`
    font-size: 15px;
    color: #FFFFFF;
    max-width: 90%;
`;