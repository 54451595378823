import React, { useRef, useEffect, useState } from 'react';
import * as S from './styles';
import { Container, Row, Col } from 'reactstrap';
import LogoPq from '../../assets/img/logo_pq.png';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import ImagemPqSobre from '../../components/ImagemPqSobre';
import EstatutoSobre from '../../components/EstatutoSobre';
import MaiorPag from '../../assets/svg/Maior';
import MenorPag from '../../assets/svg/Menor';
import CardHistoria from '../../components/CardHistoria';
import Axios from '../../utils/Axios';
import HTMLReactParser from 'html-react-parser';
import Loading from '../../components/Loading';

const Sobre = () => {
  const [loading, setLoading] = useState(true);
  const carousel = useRef(null);
  const [dadosSobre, setDadosSobre] = useState([]);
  const [texto, setTexto] = useState(null);
  const [estatuto, setEstatuto] = useState("");
  const [dados, setDados] = useState([]);
  const [dadosDiretoria, setDadosDiretoria] = useState([]);
  const [dadosDelegadosPortoAlegre, setDadosDelegadosPortoAlegre] = useState([]);
  const [dadosDelegadosPelotas, setDadosDelegadosPelotas] = useState([]);
  const [dadosDelegadosCaxias, setDadosDelegadosCaxias] = useState([]);
  const [dadosDelegadosPassoFundo, setDadosDelegadosPassoFundo] = useState([]);
  const [dadosDelegadosSantaMaria, setDadosDelegadosSantaMaria] = useState([]);
  const [dadosDelegadosAlegrete, setDadosDelegadosAlegrete] = useState([]);
  const [dadosDelegadosSantoAngelo, setDadosDelegadosSantoAngelo] = useState([]);

  const handleMoviLeft = () => {
    carousel.current.scrollLeft -= carousel.current.offsetWidth+20;
  };

  const handleMoviRight = () => {
    carousel.current.scrollLeft += carousel.current.offsetWidth-20;
  };


  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/sobre`).then((response) => {
      setDadosSobre(response.data)
    })

    Axios.get(`/historias`).then((response) => {
      setDados(response.data)
    })
    
    Axios.get(`/diretorias/diretoria`).then((response) => {
      setDadosDiretoria(response.data)
    })

    Axios.get(`/delegados/portoAlegre`).then((response) => {
      setDadosDelegadosPortoAlegre(response.data);
    })

    Axios.get(`/delegados/pelotas`).then((response) => {
      setDadosDelegadosPelotas(response.data);
    })

    Axios.get(`/delegados/caxias`).then((response) => {
      setDadosDelegadosCaxias(response.data);
    })

    Axios.get(`/delegados/passoFundo`).then((response) => {
      setDadosDelegadosPassoFundo(response.data);
    })

    Axios.get(`/delegados/santaMaria`).then((response) => {
      setDadosDelegadosSantaMaria(response.data);
    })
    
    Axios.get(`/delegados/alegrete`).then((response) => {
      setDadosDelegadosAlegrete(response.data);
    })
    
    Axios.get(`/delegados/santoAngelo`).then((response) => {
      setDadosDelegadosSantoAngelo(response.data);
    })

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    setTexto(dadosSobre?.texto);
    setEstatuto(dadosSobre?.estatuto);
  }, [dadosSobre])

  return (
    <>
      <Header min="true" name="Sobre"/>
        <Container style={{ maxWidth: "94%" }}>
          <Row>
          {loading ? <Loading /> :
            <Col md={12}>
              <S.TextRight>{HTMLReactParser(String(texto))}</S.TextRight>
            </Col>
          }
          </Row>
        </Container>  
        <EstatutoSobre estatuto={estatuto} />
        <Container style={{ maxWidth: "94%"}}>
          <Row>
            <Col md={12}>
              <S.DivTitulos>
                <img src={LogoPq} alt="" />
                <S.TitleBody>Nossa História</S.TitleBody>
              </S.DivTitulos>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <S.Center>
                <S.ButtonCardEvento style={{ marginRight: "20px" }} onClick={() => handleMoviLeft()} ><MenorPag /></S.ButtonCardEvento>
                  <S.DivCardEventos ref={carousel}>
                  {dados !== undefined && dados.map((item, index) => (
                    <CardHistoria data={item.data} texto={item.texto} ano={item.ano} imagem={item.imagem} key={index} />
                  ))}  
                  </S.DivCardEventos>
                <S.ButtonCardEvento style={{ marginLeft: "20px" }} onClick={() => handleMoviRight()} ><MaiorPag /></S.ButtonCardEvento>
              </S.Center>
            </Col>
          </Row>
          <Row style={{ marginTop: "50px" }}>
            <Col md={12}>
              <S.DivTitulos>
                <img src={LogoPq} alt="" />
                <S.TitleBody>Nossa Diretoria</S.TitleBody>
              </S.DivTitulos>
            </Col>
          </Row>
          <Row>
            {dadosDiretoria !== undefined && dadosDiretoria.map((item, index) => (
              item.grupo === "diretoria" && (
                <Col xl={3} md={4} sm={6} xs={12} key={index}>
                  <ImagemPqSobre nome={item.nome} cargo={item.cargo} />
                </Col>
              )
            ))}
          </Row>
          <Row>
            <Col md={6} xl={4}>
              <S.TitleCargo>Diretorias Regionais</S.TitleCargo>
              {dadosDiretoria !== undefined && dadosDiretoria.map((item, index) => (
                item.grupo === "regionais" && (
                  <S.Dados key={index}>
                    <S.Nome>{item.nome}</S.Nome>
                    <S.Cargo>{item.cargo}</S.Cargo>
                  </S.Dados>
                )
              ))}
            </Col>
            <Col md={6} xl={4}>
              <S.TitleCargo>Suplentes Diretorias Regionais</S.TitleCargo>
              {dadosDiretoria !== undefined && dadosDiretoria.map((item, index) => (
                item.grupo === "regionaisSuplente" && (
                  <S.Dados key={index}>
                    <S.Nome>{item.nome}</S.Nome>
                    <S.Cargo>{item.cargo}</S.Cargo>
                  </S.Dados>
                )
              ))}
            </Col>
            <Col md={6} xl={4}>
              <S.TitleCargo>Conselho Fiscal</S.TitleCargo>
              {dadosDiretoria !== undefined && dadosDiretoria.map((item, index) => (
                item.grupo === "fiscal" && (
                  <S.Dados key={index}>
                    <S.Nome>{item.nome}</S.Nome>
                    {/* <S.Cargo>123456789</S.Cargo> */}
                  </S.Dados>
                )
              ))}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <S.TitleCargo>Delegados Sindicais</S.TitleCargo>
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={6} style={{ textAlign: "center", marginTop: "50px" }}>
              <S.TitleRegional>
                Regional Porto Alegre
                <S.Divider />
              </S.TitleRegional>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Porto Alegre</S.TitleRegional>
                {dadosDelegadosPortoAlegre !== undefined && dadosDelegadosPortoAlegre.map((item, index) => (
                  item.cidade === "portoAlegre" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Capão da canoa</S.TitleRegional>
                {dadosDelegadosPortoAlegre !== undefined && dadosDelegadosPortoAlegre.map((item, index) => (
                  item.cidade === "capao" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Rio pardo</S.TitleRegional>
                {dadosDelegadosPortoAlegre !== undefined && dadosDelegadosPortoAlegre.map((item, index) => (
                  item.cidade === "rioPardo" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Santa Cruz Do Sul</S.TitleRegional>
                {dadosDelegadosPortoAlegre !== undefined && dadosDelegadosPortoAlegre.map((item, index) => (
                  item.cidade === "santaCruzDoSul" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Santo Antonio da Patrulha</S.TitleRegional>
                {dadosDelegadosPortoAlegre !== undefined && dadosDelegadosPortoAlegre.map((item, index) => (
                  item.cidade === "santoAntonioDaPatrulha" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>São Sebastião Do Caí</S.TitleRegional>
                {dadosDelegadosPortoAlegre !== undefined && dadosDelegadosPortoAlegre.map((item, index) => (
                  item.cidade === "saoSebastiaoDoCai" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Sapiranga</S.TitleRegional>
                {dadosDelegadosPortoAlegre !== undefined && dadosDelegadosPortoAlegre.map((item, index) => (
                  item.cidade === "sapiranga" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Torres</S.TitleRegional>
                {dadosDelegadosPortoAlegre !== undefined && dadosDelegadosPortoAlegre.map((item, index) => (
                  item.cidade === "torres" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Tramandaí</S.TitleRegional>
                {dadosDelegadosPortoAlegre !== undefined && dadosDelegadosPortoAlegre.map((item, index) => (
                  item.cidade === "tramandai" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Triunfo</S.TitleRegional>
                {dadosDelegadosPortoAlegre !== undefined && dadosDelegadosPortoAlegre.map((item, index) => (
                  item.cidade === "triunfo" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Vera Cruz</S.TitleRegional>
                {dadosDelegadosPortoAlegre !== undefined && dadosDelegadosPortoAlegre.map((item, index) => (
                  item.cidade === "veraCruz" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={6} style={{ textAlign: "center", marginTop: "50px" }}>
              <S.TitleRegional>
              Regional Pelotas
                <S.Divider />
              </S.TitleRegional>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
          <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Pelotas</S.TitleRegional>
                {dadosDelegadosPelotas !== undefined && dadosDelegadosPelotas.map((item, index) => (
                  item.cidade === "pelotas" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Bagé</S.TitleRegional>
                {dadosDelegadosPelotas !== undefined && dadosDelegadosPelotas.map((item, index) => (
                  item.cidade === "bage" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Piratini</S.TitleRegional>
                {dadosDelegadosPelotas !== undefined && dadosDelegadosPelotas.map((item, index) => (
                  item.cidade === "piratini" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Rio grande</S.TitleRegional>
                {dadosDelegadosPelotas !== undefined && dadosDelegadosPelotas.map((item, index) => (
                  item.cidade === "rioGrande" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={6} style={{ textAlign: "center", marginTop: "50px" }}>
              <S.TitleRegional>
                Regional Caxias Do Sul
                <S.Divider />
              </S.TitleRegional>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Caxias do sul</S.TitleRegional>
                {dadosDelegadosCaxias !== undefined && dadosDelegadosCaxias.map((item, index) => (
                  item.cidade === "caxias" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Bento Gonçalves</S.TitleRegional>
                {dadosDelegadosCaxias !== undefined && dadosDelegadosCaxias.map((item, index) => (
                  item.cidade === "bentoGoncalves" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Farroupilha</S.TitleRegional>
                {dadosDelegadosCaxias !== undefined && dadosDelegadosCaxias.map((item, index) => (
                  item.cidade === "farroupilha" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>São Francisco de Paula</S.TitleRegional>
                {dadosDelegadosCaxias !== undefined && dadosDelegadosCaxias.map((item, index) => (
                  item.cidade === "saoFranciscoDePaula" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Vacaria</S.TitleRegional>
                {dadosDelegadosCaxias !== undefined && dadosDelegadosCaxias.map((item, index) => (
                  item.cidade === "vacaria" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={6} style={{ textAlign: "center", marginTop: "50px" }}>
              <S.TitleRegional>
                Passo Fundo
                <S.Divider />
              </S.TitleRegional>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Passo Fundo</S.TitleRegional>
                {dadosDelegadosPassoFundo !== undefined && dadosDelegadosPassoFundo.map((item, index) => (
                  item.cidade === "passoFundo" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Carazinho</S.TitleRegional>
                {dadosDelegadosPassoFundo !== undefined && dadosDelegadosPassoFundo.map((item, index) => (
                  item.cidade === "carazinho" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Erechim</S.TitleRegional>
                {dadosDelegadosPassoFundo !== undefined && dadosDelegadosPassoFundo.map((item, index) => (
                  item.cidade === "erechim" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Tapejara</S.TitleRegional>
                {dadosDelegadosPassoFundo !== undefined && dadosDelegadosPassoFundo.map((item, index) => (
                  item.cidade === "tapejara" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Frederico Westphalem</S.TitleRegional>
                {dadosDelegadosPassoFundo !== undefined && dadosDelegadosPassoFundo.map((item, index) => (
                  item.cidade === "frederico" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Marcelino Ramos</S.TitleRegional>
                {dadosDelegadosPassoFundo !== undefined && dadosDelegadosPassoFundo.map((item, index) => (
                  item.cidade === "marcelinoRamos" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={6} style={{ textAlign: "center", marginTop: "50px" }}>
              <S.TitleRegional>
              Santa Maria
                <S.Divider />
              </S.TitleRegional>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Santa Maria</S.TitleRegional>
                {dadosDelegadosSantaMaria !== undefined && dadosDelegadosSantaMaria.map((item, index) => (
                  item.cidade === "santaMaria" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Agudo</S.TitleRegional>
                {dadosDelegadosSantaMaria !== undefined && dadosDelegadosSantaMaria.map((item, index) => (
                  item.cidade === "agudo" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Caçapava do Sul</S.TitleRegional>
                {dadosDelegadosSantaMaria !== undefined && dadosDelegadosSantaMaria.map((item, index) => (
                  item.cidade === "cacapavaDoSul" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Cachoeira do Sul</S.TitleRegional>
                {dadosDelegadosSantaMaria !== undefined && dadosDelegadosSantaMaria.map((item, index) => (
                  item.cidade === "cachoeiraDoSul" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Santiago</S.TitleRegional>
                {dadosDelegadosSantaMaria !== undefined && dadosDelegadosSantaMaria.map((item, index) => (
                  item.cidade === "santiago" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>São Sepé</S.TitleRegional>
                {dadosDelegadosSantaMaria !== undefined && dadosDelegadosSantaMaria.map((item, index) => (
                  item.cidade === "saoSepe" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>São Borja</S.TitleRegional>
                {dadosDelegadosSantaMaria !== undefined && dadosDelegadosSantaMaria.map((item, index) => (
                  item.cidade === "saoBorja" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Tupanciretã</S.TitleRegional>
                {dadosDelegadosSantaMaria !== undefined && dadosDelegadosSantaMaria.map((item, index) => (
                  item.cidade === "tupancireta" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={6} style={{ textAlign: "center", marginTop: "50px" }}>
              <S.TitleRegional>
                Alegrete
                <S.Divider />
              </S.TitleRegional>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Alegrete</S.TitleRegional>
                {dadosDelegadosAlegrete !== undefined && dadosDelegadosAlegrete.map((item, index) => (
                  item.cidade === "alegrete" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Dom Pedrito</S.TitleRegional>
                {dadosDelegadosAlegrete !== undefined && dadosDelegadosAlegrete.map((item, index) => (
                  item.cidade === "domPedrito" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Livramento</S.TitleRegional>
                {dadosDelegadosAlegrete !== undefined && dadosDelegadosAlegrete.map((item, index) => (
                  item.cidade === "livramento" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Rosário</S.TitleRegional>
                {dadosDelegadosAlegrete !== undefined && dadosDelegadosAlegrete.map((item, index) => (
                  item.cidade === "rosario" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Uruguaiana</S.TitleRegional>
                {dadosDelegadosAlegrete !== undefined && dadosDelegadosAlegrete.map((item, index) => (
                  item.cidade === "uruguaiana" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={6} style={{ textAlign: "center", marginTop: "50px" }}>
              <S.TitleRegional>
                Santo Ângelo
                <S.Divider />
              </S.TitleRegional>
            </Col>
            <Col md={3}></Col>
          </Row>
          <Row>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Santo Ângelo</S.TitleRegional>
                {dadosDelegadosSantoAngelo !== undefined && dadosDelegadosSantoAngelo.map((item, index) => (
                  item.cidade === "santoAngelo" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Cruz Alta</S.TitleRegional>
                {dadosDelegadosSantoAngelo !== undefined && dadosDelegadosSantoAngelo.map((item, index) => (
                  item.cidade === "cruzAlta" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Giruá</S.TitleRegional>
                {dadosDelegadosSantoAngelo !== undefined && dadosDelegadosSantoAngelo.map((item, index) => (
                  item.cidade === "girua" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Panambi</S.TitleRegional>
                {dadosDelegadosSantoAngelo !== undefined && dadosDelegadosSantoAngelo.map((item, index) => (
                  item.cidade === "panambi" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Ijuí</S.TitleRegional>
                {dadosDelegadosSantoAngelo !== undefined && dadosDelegadosSantoAngelo.map((item, index) => (
                  item.cidade === "ijui" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
              <S.Grid>
                <S.TitleRegional style={{ marginTop: "30px" }}>Santa Rosa</S.TitleRegional>
                {dadosDelegadosSantoAngelo !== undefined && dadosDelegadosSantoAngelo.map((item, index) => (
                  item.cidade === "santaRosa" && (
                    <S.Nome key={index}>{item.nome}</S.Nome>
                  )
                ))}
              </S.Grid>
            </Col>
          </Row>
        </Container>
      <Footer />
      <Assinatura />
    </>
  );
};

export default Sobre;