import React from 'react';

function AMenos(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.168" height="29.06" viewBox="0 0 29.168 29.06" fill="none" {...props}>
            <g id="Grupo_3176" data-name="Grupo 3176" transform="translate(-8307.373 -6233.582)">
                <path id="Caminho_451" data-name="Caminho 451" d="M8321.612,6245.489c-.072-.243-.124-.433-.155-.575q-.195.751-1.108,3.709h2.233Q8321.721,6245.852,8321.612,6245.489Z" fill="#b3b1b1"/>
                <path id="Caminho_452" data-name="Caminho 452" d="M8321.9,6262.641a14.529,14.529,0,0,0,.221-29.058h-.221a14.53,14.53,0,0,0,0,29.059Zm4.114-24.372h3.967a.535.535,0,0,1,.538.534h0a.535.535,0,0,1-.535.536h-3.931a.536.536,0,1,1-.039-1.071Zm-5.68,5.318h2.221l3.036,8.6h-1.958l-.621-2.04h-3.123l-.62,2.04h-1.958Z" fill="#b3b1b1"/>
            </g>
        </svg>
    )
}

export default AMenos;