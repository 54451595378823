import styled from 'styled-components';
import Maps from '../../assets/svg/Maps';

export const ButtonAcao = styled.div`
    width: 100%;
    height: 42px;
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: space-between;
    margin-top: 280px;
    padding: 0 10px;
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 94%;
    margin-top: 35px;
    margin-left: 3%;
`;

export const ButtonCardEvento = styled.button`
    min-width: 38px;  
    height: 400px;
    border: 0px;
    background-color: #FFFFFF;

    & path {
        fill: #CBCBCB;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const DivCardEventos = styled.div`
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: 100%;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 768px) {
        display: none;
    } 
`;

export const DivCardEventosMobile = styled.div`
    display: none;

    @media (max-width: 768px) {
        display: flex;
        overflow-x: auto;
        scroll-behavior: smooth;
        width: 100%;
        height: 657px;
    } 

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const DivMaps = styled.div`
    display: flex;
    justify-content: end;
`;

export const CustomMaps = styled(Maps)`
    position: relative; 
    z-index: 99;
    margin-top: -405px;
`;

export const Title = styled.text`
    font-size: 35px;
    font-weight: bold;
    color: #FFFFFF;
`;

export const Text = styled.text`
    font-size: 15px;
    color: #FFFFFF;
`;

export const Right = styled.div`
    width: 100%;
    height: 400px;
    background-color: #004070;
    border-radius: 0 15px 15px 0;
`;