import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Assinatura from '../../../components/Assinatura';
import Axios from '../../../utils/Axios';
import HTMLReactParser from 'html-react-parser';
import Loading from '../../../components/Loading';

const NoticiasDetalhes = () => {
  const [fonte, setFonte] = useState(2);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [modal, setModal] = useState(false);
  const [imgTemp, setImgTemp] = useState("");
  const [legendTemp, setLegendTemp] = useState("");

  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/multimidias/${id}`).then((response) => {
      setDados(response.data);
      setLoading(false);
    })
  }, [id]);

  const getImg = (item) => {
    setImgTemp(item.imagem);
    setLegendTemp(item.legenda);
    setModal(true);
  }

  const imagens = 
    [
      {"imagem": dados.imagem0, "legenda": dados.legenda0}, 
      {"imagem": dados.imagem1, "legenda": dados.legenda1}, 
      {"imagem": dados.imagem2, "legenda": dados.legenda2}, 
      {"imagem": dados.imagem3, "legenda": dados.legenda3}, 
      {"imagem": dados.imagem4, "legenda": dados.legenda4}, 
      {"imagem": dados.imagem5, "legenda": dados.legenda5}, 
      {"imagem": dados.imagem6, "legenda": dados.legenda6}, 
      {"imagem": dados.imagem7, "legenda": dados.legenda7}, 
      {"imagem": dados.imagem8, "legenda": dados.legenda8}, 
      {"imagem": dados.imagem9, "legenda": dados.legenda9}, 
      {"imagem": dados.imagem10, "legenda": dados.legenda10}, 
      {"imagem": dados.imagem11, "legenda": dados.legenda11}, 
      {"imagem": dados.imagem12, "legenda": dados.legenda12}, 
      {"imagem": dados.imagem13, "legenda": dados.legenda13}, 
      {"imagem": dados.imagem14, "legenda": dados.legenda14}, 
      {"imagem": dados.imagem15, "legenda": dados.legenda15}, 
      {"imagem": dados.imagem16, "legenda": dados.legenda16}, 
      {"imagem": dados.imagem17, "legenda": dados.legenda17}, 
      {"imagem": dados.imagem18, "legenda": dados.legenda18}, 
      {"imagem": dados.imagem19, "legenda": dados.legenda19}, 
      
    ]

    console.log(dados, "dados")

  return (
    <>
      <S.ModalTemp modal={modal}>
        <S.Grid>
          <img src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${imgTemp}`} alt=""  />
          <S.TextLegenda>{legendTemp}</S.TextLegenda>
        </S.Grid>
        <S.DivX>
          <S.Xis onClick={()=> setModal(false)}>X</S.Xis>
        </S.DivX>
      </S.ModalTemp>
      <Header min="true" name="Multimidias"/>
      <Container style={{ maxWidth: "80%"}}>
        <S.DivButton>
          <S.DivFonte>
            <S.CustomButton onClick={() => setFonte(fonte + 1) } disabled={fonte === 4 ? true : false} >
              <S.CustomAMais />
            </S.CustomButton>
            <S.CustomButton onClick={() => setFonte(fonte - 1) } disabled={fonte === 0 ? true : false}>
              <S.CustomAMenos />
            </S.CustomButton>
          </S.DivFonte>
        </S.DivButton>
        {loading ? <Loading /> :
        <>
          <Row>
            <>
              <Col md={12}>
                <S.Title fonte={fonte}>{dados.cartola}</S.Title>
              </Col>
              <Col md={12}>
                <S.SubTitle fonte={fonte}>{dados.titulo}</S.SubTitle>
              </Col>
              <Col md={12}>
                <S.Text fonte={fonte}>{HTMLReactParser(String(dados.texto.replace('class="ql-align-center"', 'class="ql-align-center" style="display: flex; justify-content: center"')))}</S.Text>
              </Col>
            </>
          </Row>
          <Row>
            <S.Gallery>
              {imagens.map((item, index) => (
                <S.Center onClick={() => getImg(item)} key={index}>
                  <img src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${item.imagem}`} alt="" style={{ width: "100%" }} />
                </S.Center>
              ))}
            </S.Gallery>
          </Row>
        </>
        }
      
      
      </Container>  
      <Footer />
      <Assinatura />
    </>
  );
};

export default NoticiasDetalhes;