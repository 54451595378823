import React from 'react';

function Lupa(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.911" height="17.093" viewBox="0 0 16.911 17.093" fill="none" {...props}>
            <path id="União_1" data-name="União 1" d="M15.588,16.867,9.349,10.655a5.93,5.93,0,0,1-7.62-.62,5.861,5.861,0,0,1,0-8.313,5.924,5.924,0,0,1,8.352,0,5.861,5.861,0,0,1,.4,7.876l6.206,6.178a.771.771,0,0,1-.548,1.316A.776.776,0,0,1,15.588,16.867ZM2.922,2.909a4.19,4.19,0,0,0,0,5.938,4.236,4.236,0,0,0,5.965,0A4.208,4.208,0,0,0,2.922,2.909Z" fill="#7bc7ff"/>
        </svg>
    )
}

export default Lupa;