import styled from 'styled-components';
import DestaqueImg from '../../assets/img/sede_adm.jpg';
import { UncontrolledCarousel } from 'reactstrap';

export const CustomUncontrolledCarousel = styled(UncontrolledCarousel)`
    border: 8px solid #A5D4F6;
    border-radius: 15px;

    img {
        width: 100%;
        height: 100%;
        max-width: 677px;
        max-height: 570px;
        object-fit: contain;
        border-radius: 10px;
    }

    .carousel-indicators button {
        width: 16px !important;
        height: 16px !important;
        border-radius: 50%;
        border: 1px solid #004070;

        &.active {
            background-color: #004070;
            border: 1px solid #FFFFFF;
        }
    }
`;

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: 100px;

    @media (max-width: 1520px) {
        height: 100px;
        align-items: center;
    }

    @media (max-width: 1200px) {
        height: 50px;
    }

    @media (max-width: 1000px) {
        height: 100px;
    }

    @media (max-width: 768px) {
        height: 50px;
        margin-top: 60px;
    }

    @media (max-width: 440px) {
        height: 100px;
        margin-bottom: 20px;
    }
`;

export const TitleBody = styled.div`
    font-size: 30px;
    font-weight: bold;
    color: #004070;
    margin-left: 15px;
    height: 50px;
`;

export const TextBody = styled.text`
    font-size: 18px;
    color: #707070;
`;

export const DivDestaque = styled.div`
    position: relative;
    float: left;
    clear: none;
    width: 679px;
    height: 574px;
    margin-right: 50px;
    margin-top: 90px;
    background-image: url(${DestaqueImg});
    background-repeat: no-repeat;
    background-position: center;
    z-index: 4;
    border-radius: 20px;

    @media (max-width: 768px) {
        width: 543px;
        height: 429px;
    }

    @media (max-width: 600px) {
        width: 455px;
        height: 360px;
    }

    @media (max-width: 500px) {
        width: 279px;
        height: 237px;
        margin-bottom: 20px;
    }
`;

export const TextRight = styled.div`
    font-size: 18px;
    color: #000000;
    margin-top: 50px;

    @media (max-width: 1200px) {
        margin-top: 50px;
    }
`;

export const Conteiner = styled.div`
    display: flex;

    @media (max-width: 1200px) {
        display: grid;
    }
`;
    
export const Right = styled.div`
    align-items: center;
    padding-left: 50px;

    @media (max-width: 1200px) {
        padding-left: 0px;
    }
`;
    


export const DivTop = styled.div`
    position: absolute; 
    top: 1090px;

    @media (max-width: 1250px) {
        top: 1090px;
    }

    @media (max-width: 1200px) {
        top: 1520px;
    }

    @media (max-width: 1000px) {
        top: 1540px;
    }

    @media (max-width: 950px) {
        top: 1560px;
    }

    @media (max-width: 820px) {
        top: 1580px;
    }

    @media (max-width: 770px) {
        top: 1500px;
    }

    @media (max-width: 724px) {
        top: 1530px;
    }

    @media (max-width: 650px) {
        top: 1440px;
    }

    @media (max-width: 570px) {
        top: 1480px;
    }

    @media (max-width: 550px) {
        top: 1430px;
    }

    @media (max-width: 505px) {
        top: 1460px;
    }

    @media (max-width: 450px) {
        top: 1390px;
    }

    @media (max-width: 440px) {
        top: 1440px;
    }

    @media (max-width: 431px) {
        top: 1460px;
    }

    @media (max-width: 428px) {
        top: 1480px;
    }
`;

export const CustomFoto2 = styled.img`
    border: 0;
    width: 677px;
    height: 570px;
    border-radius: 20px;

    @media (max-width: 1250px) {
        width: 677px;
        height: 565px;
    }

    @media (max-width: 1200px) {
        width: 677px;
        height: 560px;
    }

    @media (max-width: 1000px) {
        width: 677px;
        height: 520px;
    }

    @media (max-width: 750px) {
        width: 650px;
        height: 528px;
    }

    @media (max-width: 650px) {
        width: 548px;
        height: 440px;
    }

    @media (max-width: 635px) {
        width: 548px;
        height: 420px;
    }

    @media (max-width: 570px) {
        width: 520px;
        height: 420px;
    }

    @media (max-width: 550px) {
        width: 450px;
        height: 370px;
    }

    @media (max-width: 530px) {
        width: 450px;
        height: 345px;
    }

    @media (max-width: 505px) {
        width: 450px;
        height: 370px;
    }

    @media (max-width: 504px) {
        width: 450px;
        height: 350px;
    }

    @media (max-width: 450px) {
        width: 350px;
        height: 280px;
    }

    @media (max-width: 428px) {
        width: 350px;
        height: 270px;
    }
    
`;

export const Left = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 1200px) {
        display: flex;
        justify-content: center;
    }
`;

export const CustomFoto = styled.img`
    border: 0;
    max-width: 100%;
    width: 677px;
    height: 570px;
    object-fit: cover;
    border-radius: 15px 0 15px 0;
`;

export const CustomMoldura = styled.img`
    border: 0;

    @media (max-width: 750px) {
        width: 650px;
    }

    @media (max-width: 650px) {
        width: 550px;
    }

    @media (max-width: 550px) {
        width: 450px;
    }

    @media (max-width: 450px) {
        width: 350px;
    }
`;

export const DivMoldura = styled.div`
    position: relative;
    float: left;
    clear: none;
    width: 100%;
    height: 574px;
    margin-right: 50px;
    margin-top: 90px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 99;
    border-radius: 20px;

    @media (max-width: 991px) {
        width: 100%;
    }

`;

export const FundoGrid = styled.div`
    display: grid;
    justify-content: start;
`;

export const TitleBodyBlue = styled.text`
    font-size: 35px;
    color: #004070;
    font-weight: bold;
    margin-left: 10px;
`;

export const TextPqContatoBlue = styled.text`
    color: #004070;
    font-size: 15px;
    margin-left: 60px;
`;

export const Center = styled.div`
    display: flex;
    justify-content: center;
`;

export const ButtonCardEvento = styled.button`
    width: 40px;  
    height: 200px;
    border: 0px;
    background-color: #FFFFFF;

    & path {
        fill: #CBCBCB;
    }
`;

export const DivCardEventos = styled.div`
    display: flex;
    width: 800%;
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: 20px;
    
    &::-webkit-scrollbar {
        display: none;
    }
`;