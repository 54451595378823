import React from 'react';
import * as S from './styled';
import PDF from '../../assets/svg/PDF';

const ButtonPdf = (props) => {

    return (
        <S.Container>
            <S.Left>
                <PDF />
            </S.Left>
            <S.Right>
                <S.TextRight>
                    Baixar estatuto
                </S.TextRight>
            </S.Right>
        </S.Container>
    )
}

export default ButtonPdf;