import styled from 'styled-components';
import Imagem from '../../assets/img/imagem.png';
import DestaqueImg from '../../assets/img/destaque.png';
import Moldura from '../../assets/img/moldura.png';

export const DestaqueSobre = styled.div`
    max-height: 100%;
    min-height: 100%;
    max-width: 100%;
    min-width: 100%;
    background-image: url(${Imagem});
    background-repeat: no-repeat;
    background-position: center;
    Background-size: contain;
    position: absolute;
    z-index: 5;

    @media (max-width: 768px) {
        border-radius: 15px;
        height: 200px;
    }
`;

export const ImagemDestaqueSobre = styled.div`
    max-height: 50%;
    min-height: 50%;
    max-width: 50%;
    min-width: 50%;
    border-radius: 15px;
    background-color: #0000FF;
    background-image: url(${DestaqueImg});
    background-repeat: no-repeat;
    background-position: center;
    Background-size: contain;
    position: absolute;
    z-index: 5;

    @media (max-width: 768px) {
        border-radius: 15px;
        height: 200px;
    }
`;

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: 50px;
`;

export const DivDestaque = styled.div`
    position: relative;
    float: left;
    clear: none;
    width: 679px;
    height: 574px;
    margin-right: 50px;
    margin-top: 90px;
    background-image: url(${DestaqueImg});
    background-repeat: no-repeat;
    background-position: center;
    z-index: 4;

    @media (max-width: 768px) {
        width: 543px;
        height: 459px;
    }

    @media (max-width: 600px) {
        width: 476px;
        height: 402px;
    }

    @media (max-width: 500px) {
        width: 339px;
        height: 287px;
        margin-bottom: 20px;
    }
`;

export const DivMoldura = styled.div`
    position: absolute;
    float: left;
    clear: none;
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    min-height: 100%;
    margin-top: -10px;
    background-image: url(${Moldura});
    background-repeat: no-repeat;
    background-position: center;
    Background-size: contain;
    z-index: -4;

    @media (max-width: 768px) {
        margin-top: 0px;
    }
`;

export const TitleBody = styled.div`
    font-size: 30px;
    font-weight: bold;
    color: #004070;
    margin-left: 15px;
    height: 50px;
    display: flex;
    padding-top: 3px;
`;

export const TextRight = styled.div`
    font-size: 18px;
    color: #000000;
    margin-top: 80px;
`;

export const Center = styled.div`
    display: flex;
    justify-content: center;
    min-height: 120%;
`;

export const ButtonCardEvento = styled.button`
    width: 40px;  
    height: 200px;
    border: 0px;
    background-color: #FFFFFF;

    & path {
        fill: #CBCBCB;
    }
`;

export const DivCardEventos = styled.div`
    display: flex;
    width: 800%;
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: 20px;
    
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const TitleCargo = styled.div`
    font-size: 35px;
    font-weight: bold;
    color: #004070;
    margin-top: 145px;
    margin-bottom: 45px;
    height: 100px;
    display: flex;
    align-items: end;

    @media(max-width: 850px) {
        font-size: 30px;
    }
`;

export const Dados = styled.div`
    display: grid;
    margin-top: 10px;
`;

export const Nome = styled.text`
    font-size: 20px;
    font-weight: bold;
    color: #7BC7FF;
`;

export const Cargo = styled.text`
    font-size: 15px;
    color: #707070;
`;

export const TitleRegional = styled.text`
    font-size: 20px;
    color: #004070;
    font-weight: bold;
    background-color: #FFFFFF;
`;

export const Divider = styled.div`
    border-top: 3px solid #7BC7FF;
    height: 10px;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    margin-top: -15px;
`;

export const Grid = styled.div`
    display: grid;
`;
