import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    height: 300px;
    min-width: 32%;
    text-align: center;

    @media (max-width: 1470px) {
        min-width: 50%;
    }

    @media (max-width: 1000px) {
        min-width: 100%;
    }
`;

export const Title = styled.text`
    color: #7BC7FF;
    font-size: 35px;
    font-weight: bold;
`;

export const Text = styled.text`
    font-size: 15px;
    color: #7E7E7E;
`;

export const Imagem = styled.div`
    width: 100%;
    height: 50px;
`;

export const Ano = styled.div`
    width: 100px;
    height: 100px;
    font-size: 35px;
    font-weight: bold;
    color: #004070;
    border: 2px solid #004070;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Center = styled.div`
    display: flex;
    justify-content: center;
`;