import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import * as S from './styled';
import Audio from '../../components/Audio';
// import Face from '../../assets/svg/Face';
// import Twitter from '../../assets/svg/Twitter';
// import Whats from '../../assets/svg/Whats';
// import FaceAzul from '../../assets/svg/FaceAzul';
// import TwitterAzul from '../../assets/svg/TwitterAzul';
// import WhatsAzul from '../../assets/svg/WhatsAzul';
// import FaceCinza from '../../assets/svg/FaceCinza';
// import TwitterCinza from '../../assets/svg/TwitterCinza';
// import WhatsCinza from '../../assets/svg/WhatsCinza';
import Multimidia from '../../components/Multimidia';
import Axios from '../../utils/Axios';
import Loading from '../../components/Loading';
import ModalFull from '../../components/ModalFull';

const CardNoticias = (props) => {
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [face, setFace] = useState("");
    const [insta, setInsta] = useState("");

    useEffect( () => {
        let token = localStorage.getItem('token');
        Axios.defaults.headers.Authorization = `Bearer ${token}`;
        Axios.get(`/noticiasHome`).then((response) => {
        setDados(response.data);
        setLoading(false);
        })
    }, []);

    return (
        <>
            {modal && 
                <ModalFull
                    modal={modal} setModal={setModal} 
                    acao="news" 
                    face={face}
                    insta={insta}
                />
            }
            {loading ? <Loading /> :
            dados !== undefined && dados.map((item, index) => (
                item.modelo === "2" ?
                    <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                        <S.DivCard>
                            {item.insta !== "undefined" && item.face !== "undefined" ?
                                <S.DivModal onClick={() => {setModal(!modal); setFace(item.face); setInsta(item.insta) }}>
                                    <S.Imagem picture={item.picture} color={item.color} />
                                </S.DivModal>
                            : item.insta !== "undefined" ?
                                <a target="_blank" rel="noreferrer" href={item.insta}>
                                    <S.Imagem picture={item.picture} color={item.color} />
                                </a>
                            : item.face !== "undefined" ?
                                <a target="_blank" rel="noreferrer" href={item.face}>
                                    <S.Imagem picture={item.picture} color={item.color} />
                                </a>
                            :
                                ""
                            }
                        </S.DivCard>
                    </Col>
                : item.modelo === "3" ?
                    <Col xl={6} md={6} style={{ marginTop: "20px" }} key={index}>
                        <S.DivCard>
                            <S.Duplo>
                                <S.DivMultimidia>
                                    <Multimidia height={"100%"} width={"100%"} minWidth={"100%"} maxWidth={"100%"} urlYoutube={item.urlYoutube} />
                                </S.DivMultimidia>
                                <S.DivLink to={"/noticias/detalhes/"+item.id}>
                                    <S.FundoDuplo cor="white">
                                        <S.TextosDuplo>
                                            <S.Lapela>
                                                {/* {item.cartola} */}
                                            </S.Lapela>
                                            <S.TitleDuplo cor="white">{item.titulo}</S.TitleDuplo>
                                            <S.TextDuplo cor="white">{item.chamada}.</S.TextDuplo>
                                        </S.TextosDuplo>
                                        <S.BotoesDuplo>
                                            {/* <S.Divider />
                                            <S.RedeSociais>
                                                <S.Text2 cor="white">Compartilhar</S.Text2>
                                                <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                                    <FaceCinza width="30px" height="30px" />
                                                </a>
                                                <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                                    <TwitterCinza width="30px" height="30px" />
                                                </a>
                                                <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`} data-action="share/whatsapp/share">
                                                    <WhatsCinza width="30px" height="30px" />
                                                </a>
                                            </S.RedeSociais> */}
                                        </S.BotoesDuplo>
                                    </S.FundoDuplo>
                                </S.DivLink>
                            </S.Duplo>
                        </S.DivCard>
                    </Col>
                : item.modelo === "4" ?
                    <Col xl={6} md={6} style={{ marginTop: "20px" }} key={index}>
                        <S.DivCard>
                            <S.Duplo>
                                <S.DivLink to={"/noticias/detalhes/"+item.id}>
                                    <S.ImagemDuplo picture={item.picture}/>
                                </S.DivLink>
                                <S.FundoDuplo cor={item.colorDuplo}>
                                    <S.DivLink to={"/noticias/detalhes/"+item.id}>
                                        <S.TextosDuplo>
                                            <S.Lapela>
                                                {/* {item.cartola} */}
                                            </S.Lapela>
                                            <S.TitleDuplo cor={item.colorDuplo} tamanho={item.titulo.length}>{item.titulo}</S.TitleDuplo>
                                            <S.TextDuplo cor={item.colorDuplo}>{item.chamada}</S.TextDuplo>
                                        </S.TextosDuplo>
                                    </S.DivLink>
                                    <S.BotoesDuplo>
                                        {/* <S.Divider />
                                        <S.RedeSociais>
                                            <S.Text2 cor="white">Compartilhar</S.Text2>
                                            <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                                <FaceCinza width="30px" height="30px" />
                                            </a>
                                            <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                                <TwitterCinza width="30px" height="30px" />
                                            </a>
                                            <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`} data-action="share/whatsapp/share">
                                                <WhatsCinza width="30px" height="30px" />
                                            </a>
                                        </S.RedeSociais> */}
                                    </S.BotoesDuplo>
                                </S.FundoDuplo>
                            </S.Duplo>
                        </S.DivCard>
                    </Col>
                : item.modelo === "5" ?
                    <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                        <S.Fundo cor="secundary" to={"/noticias/detalhes/"+item.id}>
                            <S.Lapela cor="white">
                                {/* {item.cartola} */}
                            </S.Lapela>
                            <Audio size="medio" cor="azul_claro" />
                            <S.TextPq cor="primary" style={{ marginTop: "-170px" }}>{item.chamada}</S.TextPq>
                            <S.BotoesAudio>
                                {/* <S.Divider cor="primary" />
                                <S.RedeSociais>
                                    <S.Text2 cor="primary">Compartilhar</S.Text2>
                                    <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                        <FaceAzul width="30px" height="30px" />
                                    </a>
                                    <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                        <TwitterAzul width="30px" height="30px" />
                                    </a>
                                    <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`} data-action="share/whatsapp/share">
                                        <WhatsAzul width="30px" height="30px" />
                                    </a>
                                </S.RedeSociais> */}
                            </S.BotoesAudio>
                        </S.Fundo>
                    </Col>
                : item.modelo === "6" ?
                    <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                        <S.DivCard>
                            <S.Fundo>
                                <S.DivLink to={"/noticias/detalhes/"+item.id}>
                                    <S.Textos>
                                        <S.Lapela>
                                            {/* {item.cartola} */}
                                        </S.Lapela>
                                        <S.Title>{item.titulo}</S.Title>
                                        {item.titulo.length > 80 ? "" : <S.Text>{item.chamada}</S.Text>}
                                    </S.Textos>
                                </S.DivLink>
                                <S.Botoes>
                                    {/* <S.Divider />
                                    <S.RedeSociais>
                                        <S.Text2>Compartilhar</S.Text2>
                                        <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                            <Face width="30px" height="30px" />
                                        </a>
                                        <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                            <Twitter width="30px" height="30px" />
                                        </a>
                                        <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`} data-action="share/whatsapp/share">
                                            <Whats width="30px" height="30px" />
                                        </a>
                                    </S.RedeSociais> */}
                                </S.Botoes>
                            </S.Fundo>
                        </S.DivCard>
                    </Col>
                : ""
            ))}
        </>
    )
}

export default CardNoticias;