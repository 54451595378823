import React from 'react';
import * as S from './styles';
import Face from '../../assets/svg/Face';
import Twitter from '../../assets/svg/Twitter';
import Whats from '../../assets/svg/Whats';

const Destaque = ({cartola, chamada, titulo, picture, id}) => {
    return (
        <S.DivDestaque>
            <S.Left to={"/noticias/detalhes/"+id}>
                <S.ImgDestaque picture={picture} />
                <S.DivMarcacao>
                    <S.Marcacao />
                </S.DivMarcacao>    
            </S.Left>
            <S.Right>
                <S.Textos to={"/noticias/detalhes/"+id}>
                    <S.Tag>{cartola}</S.Tag>
                    <S.Title>{titulo}</S.Title>
                    <S.Text>{chamada}</S.Text>
                </S.Textos>
                <S.Botoes>
                    <S.Divider />
                    <S.RedeSociais>
                        <S.TextCompartilhar>Compartilhar</S.TextCompartilhar>
                        <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${id}`}>
                            <Face />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${id}`}>
                            <Twitter />
                        </a>
                        <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${id}`} data-action="share/whatsapp/share">
                            <Whats />
                        </a>
                    </S.RedeSociais>
                </S.Botoes>
            </S.Right>
        </S.DivDestaque>
    );
};

export default Destaque;