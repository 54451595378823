import styled from 'styled-components';
import { Input } from 'reactstrap';
import { Link } from "react-router-dom";
import Email from '../../assets/svg/Email';
import Telefone from '../../assets/svg/Telefone';

export const Container = styled.section`
    display: flex;
    background-color: #132448;
    width: 100%;
    height: 160px;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    position: relative;

    @media (max-width: 678px) {
        border-bottom: 1px solid #F27C00;
    }
`;

export const Left = styled.div`
    position: absolute;
    z-index: 4;
    left: 4%;
`;

export const DivCenter = styled(Link)`
    display: flex;
    text-decoration: none;
`;

export const Right = styled.div`
    display: grid;
    position: absolute;
    z-index: 4;
    right: 4%;
`;

export const RedeSociais = styled.div`
    display: flex;
    max-width: 400px;
    gap: 25px;

    @media (max-width: 1367px) {
        margin-right: 8%;
        display: none;
    }
`;

export const Text = styled.text`
    font-size: 13px;
    color: #7BC7FF;
`;

export const DivSearch = styled.div`
    max-width: 100%;
    height: 30px;
    border-radius: 20px;
    border: 1px solid #7BC7FF;
    margin-top: 20px;
    display: flex;

    @media (max-width: 1367px) {
        margin-right: 8%;
        display: none;
    }
`;

export const CustomInputText = styled(Input)`
    border: 0px;
    width: 90%;
    height: 30px;
    background: #00000000;
    color: #7BC7FF;

    &:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
        background: var(--primary);
        color: #7BC7FF;
        border: 0px;
    }

    &::-webkit-input-placeholder {
        color: #7BC7FF;
    }
`;

export const ContainerMenu = styled.section`
    display: flex;
    background-color: #13244870;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    position: relative;
    gap: 45px;
    // border-top: 1px solid #F27C00;
    // border-bottom: 1px solid #F27C00;

    @media (max-width: 1367px) {
        display: none;
    }
`;

export const BorderGradient = styled.div`
    display: none;

    @media (min-width: 1366px) {
        display: flex;
        height: 2px;
        z-index: 9999;
        position: relative;
        background-image: linear-gradient( to right, transparent, #F27C00, transparent )
    }
`;

export const ItensMenu = styled.section`
    display: flex;
    gap: 20px;
    opacity: 1;   
`;

export const NavLink = styled(Link)`
    color: #FFFFFF;
    text-decoration: none;

    &:hover {
        color: #FFFFFF99;
        text-decoration: underline;
    }
`;

export const Hamburguer = styled.section`
    cursor: pointer;
    
    @media (min-width: 1367px) {
        display: none;
    }
`;

export const X = styled.section`
    display: none;

    @media (max-width: 1366px) {
        display: flex;
        justify-content: end;
        cursor: pointer;
    }
`;

export const XText = styled.span`
    color: #FFFFFF;
    font-size: 30px;
    font-weight: bold;
`;

export const MenuMobile = styled.section`
    display: none;

    @media (max-width: 1366px) {
        display: grid;
        text-align: center;
        flex-flow: column nowrap;
        background-color: #053960;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh; 
        width: 450px;
        transform: ${({ open }) => { if (open === true) { return 'translateX(0)'; } else { return 'translateX(-100%)'; }}};
        transition: transform 0.3s ease-in-out;
        z-index: 99999;
        padding: 15px 30px;
    }
`;

export const HeaderMenu = styled.div`
    display: grid;
    width: 378px;
    height: 150px;
    margin-bottom: -300px;
`;

export const Logo = styled.div``;

export const Divider = styled.hr`
    border-top: 1px groove #7BC7FF;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    width: 100%;

    @media (max-width: 1366px) {
        width: 100%;
    }
`;

export const BodyMenu = styled.div`
    display: grid;
    height: 300px;
    width: 378px;
    margin-top: 140px;
`;

export const FooterMenu = styled.div`
    display: grid;
    height: 300px;
    width: 378px;
    margin-top: -20px;
`;

export const RedeSociaisMobile = styled.div`
    display: flex;
    max-width: 400px;
    gap: 35px;
    justify-content: center;
    margin-top: -30px;

    @media (min-width: 1367px) {
        margin-right: 8%;
        display: none;
    }
`;

export const DivSearchMenu = styled.div`
    display: flex;
    max-width: 100%;
    height: 30px;
    border-radius: 20px;
    border: 1px solid #7BC7FF;

    // @media (min-width: 1367px) {
    //     margin-right: 8%;
    //     display: none;
    // }

    @media (max-width: 678px) {
        display: flex;
    }
`;

export const Contato = styled.div`
    display: grid;
    margin-right: 10%;
    justify-content: center;
    width: 110%;

    @media (max-width: 1367px) {
        margin-top: -20px;
    }
`;

export const DivTelefone = styled.div`
    display: flex;
`;

export const CustomTelefone = styled(Telefone)`
    margim-right: 30px;
    
    & path {
        fill: #FFFFFF;
    }
`;

export const TextContatoTelefone = styled.text`
    color: #FFFFFF;
    font-size: 30px;
    font-width: 600;
    margin-left: 15px;
`;

export const TextContatoEmail = styled.text`
    color: #FFFFFF;
    font-size: 17px;
    font-width: 600;
    margin-left: 15px;
`;

export const DivEmail = styled.div`
    display: flex;
`;

export const CustomEmail = styled(Email)`
    margim-right: 30px;
    & path {
        fill: #FFFFFF;
    }
`;

export const DivImagem = styled.div`
    margin-top: ${({ min }) => { if (min === "true") { return '-400px'; } else { return '-260px'; }}};
`;

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: -80px;
`;

export const TitleBody = styled.div`
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 15px;
    height: 50px;
    width: 200px;
`;

export const DivBusca = styled.section`
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    z-index: 9999;
    position: relative;
    gap: 45px;
    padding: 0 10%;

    @media (min-width: 1367px) {
        display: none;
    }
`;

export const DivSearch2 = styled.div`
    max-width: 100%;
    height: 30px;
    border-radius: 20px;
    border: 1px solid #7BC7FF;
    margin-top: 20px;
    display: flex;

    @media (min-width: 1367px) {
        margin-right: 8%;
        display: none;
    }

    @media (max-width: 768px) {
        min-width: 100%;
    }
`;

export const DivRedesSociais = styled.div`
    display: flex;
    text-align: center;
    margin-top: 24px;
    gap: 20px;

    @media (min-width: 1367px) {
        display: none;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const ButtonSearch = styled.button`
    background-color: #A5D4F6;
    color: #132448;
    border-radius: 0 15px 15px 0;
    border: 0px;
    width: 100%;
    height: 100%;

    &:hover {
        background-color: #FFFFFF;
        color: #132448;
    }
`;

export const DivLink = styled(Link)`
    width: 100%;
    height: 100%;
`;