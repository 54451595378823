import React from 'react';
import * as S from './styles';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
// import ButtonReserva from '../../components/ButtonReserva';
import LogoPq from '../../assets/img/logo_pq.png';

const Sedes = () => {

  return (
    <>
      <Header min="true" name="Sedes"/>
      <Container style={{ maxWidth: "94%" }}>
        <Row style={{ marginTop: "100px" }}>
          <Col xl={5}>
            <S.Center>
              <S.CustomUncontrolledCarousel
                items={[
                  {
                    key: 1,
                    src: require('../../assets/img/sede_adm.jpg'),
                  },
                  {
                    key: 2,
                    src: require('../../assets/img/sede_adm_1.jpg'),
                  },
                  {
                    key: 3,
                    src: require('../../assets/img/sede_adm_2.jpg'),
                  },
                  {
                    key: 4,
                    src: require('../../assets/img/sede_adm_3.jpg'),
                  },
                  {
                    key: 5,
                    src: require('../../assets/img/sede_adm_4.jpg'),
                  },
                ]}
              />
            </S.Center>
            {/* <S.Left>
              <div style={{ position:"relative", top:"0px", left: "0px" }}>
                <S.CustomFoto src={SedeAdm} />
              </div>
              <div style={{ position: "absolute", top: "420px" }}>
                <S.CustomMoldura src={Moldura} />
              </div>
            </S.Left> */}
            {/* <ButtonReserva /> */}
            {/* <S.Left>
              <div style={{ position:"relative", top:"0px", left: "0px" }}>
                <S.CustomFoto src={SedeAdm} />
              </div>
              <div style={{ position: "absolute", top: "420px" }}>
                <S.CustomMoldura src={Moldura} />
              </div> 
            </S.Left> */}
          </Col>
          <Col xl={7}>
            <S.Right>
              <S.DivTitulos>
                <img src={LogoPq} alt="" />
                <S.TitleBody>Sede Administrativa</S.TitleBody>
              </S.DivTitulos>
              <S.TextRight>
                A Sede Administrativa localiza-se na Rua da República, 92, Cidade Baixa, Porto Alegre. É composta por recepção, sala da presidência, diretoria administrativa, assessoria jurídica, assessoria de comunicação, auditório, copa e cozinha. Possui ainda um pátio interno e uma churrasqueira.
                <br />
                <br />
                O Auditório Washington Luiz G. da Rocha tem capacidade para 60 pessoas e abriga reuniões da direção do sindicato, delegados sindicais e encontros diversos voltados à organização dos trabalhadores, em especial, dos servidores públicos.

              </S.TextRight>
            </S.Right>
          </Col>
        </Row>
        <Row>
          <Col xl={5} style={{ marginTop: "50px" }}>
            <S.Center>
              <S.CustomUncontrolledCarousel
                items={[
                  {
                    key: 1,
                    src: require('../../assets/img/sede_social.jpg'),
                  },
                  {
                    key: 2,
                    src: require('../../assets/img/sede_social_1.jpg'),
                  },
                  {
                    key: 3,
                    src: require('../../assets/img/sede_social_2.jpg'),
                  },
                  {
                    key: 4,
                    src: require('../../assets/img/sede_social_3.jpg'),
                  },
                  {
                    key: 5,
                    src: require('../../assets/img/sede_social_4.jpg'),
                  },
                ]}
              />
            </S.Center>
          </Col>
          <Col xl={7}>
              <S.Right>
                <S.DivTitulos>
                  <img src={LogoPq} alt="" />
                  <S.TitleBody>Sede Social</S.TitleBody>
                </S.DivTitulos>
                <S.TextRight>
                  Localizada na Rua Manoel Leão, 90, Pedra Redonda, Porto Alegre, a sede social conta com ampla infraestrutura, onde estão à disposição, exclusivamente aos associados e dependentes diretos, alojamentos, churrasqueiras, estacionamento e outros espaços voltados ao lazer.
                  <br />
                  <br />
                  O espaço passou, em 2017, por uma ampla reforma. A área de churrasqueiras, denominada Maria Rita Godinho Borsato, foi modernizada. Na sala de reuniões, o piso foi substituído, e o estacionamento ganhou mais vagas.
                </S.TextRight>
              </S.Right>
          </Col>
        </Row>
        <Row>
          <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" width="50px" height="50px" />
                <S.TitleBodyBlue>Regional Alegrete</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Não possui sede - Responsável: Marilene Carvalho</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Para correspondências: Sede Administrativa Porto Alegre</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col>
          {/* <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
              <img src={LogoPq} alt="" width="50px" height="50px" />
                <S.TitleBodyBlue>Regional Caxias do Sul</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Rua Hercules Gallo, 570</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Centro - Caxias do Sul</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 95020-330</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col> */}
          <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" width="50px" height="50px" />
                <S.TitleBodyBlue>Regional Passo Fundo</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Rua Guilherme Kurte, 815</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Vila Luiza - Passo Fundo</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 99072-445</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col>
          <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" width="50px" height="50px" />
                <S.TitleBodyBlue>Regional Pelotas</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Rua Dr. Cassiano, 268</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Centro - Pelotas</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 96015-700</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col>
          {/* <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" width="50px" height="50px" />
                <S.TitleBodyBlue>Regional Santa Maria</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Rua Dr. Alberto Pasqualini, 70, Sala 808</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Centro - Santa Maria</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 97015-001</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col> */}
          <Col xl={4} md={6}>
            <S.FundoGrid>
              <S.DivTitulos>
                <img src={LogoPq} alt="" width="50px" height="50px" />
                <S.TitleBodyBlue>Regional Santo Ângelo</S.TitleBodyBlue>
              </S.DivTitulos>
              <S.TextPqContatoBlue>Não possui sede- Responsável: Tania Tosi</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Para correspondências: Rua Honduras 773</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>Jardim Res. Sabo - Santo Ângelo</S.TextPqContatoBlue>
              <S.TextPqContatoBlue>CEP: 98804-180</S.TextPqContatoBlue>
            </S.FundoGrid>
          </Col>
        </Row>
      </Container>  
      <Footer />
      <Assinatura />
    </>
  );
};

export default Sedes;