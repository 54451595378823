import React from 'react';

function LinhaTempo(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="50.5" viewBox="0 0 20 50.5" fill="none" {...props}>
            <g id="Grupo_2908" data-name="Grupo 2908" transform="translate(-467 -1971)">
                <line id="Linha_8" data-name="Linha 8" y1="30" transform="translate(477.5 1990.5)" fill="none" stroke="#004070" strokeLinecap="round" strokeWidth="2"/>
                <g id="Elipse_433" data-name="Elipse 433" transform="translate(467 1971)" fill="none" stroke="#004070" strokeWidth="2">
                <circle cx="10" cy="10" r="10" stroke="none"/>
                <circle cx="10" cy="10" r="9" fill="none"/>
                </g>
            </g>
        </svg>
    )
}

export default LinhaTempo;