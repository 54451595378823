import React from 'react';

function PDF(props) {
    return (
        <svg id="Grupo_817" data-name="Grupo 817" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="51.05" height="52.906" viewBox="0 0 51.05 52.906" fill="none" {...props}>
            <defs>
                <clipPath id="clip-path">
                <rect id="Retângulo_146" data-name="Retângulo 146" width="51.05" height="52.906" fill="#004070"/>
                </clipPath>
            </defs>
            <g id="Grupo_816" data-name="Grupo 816" clipPath="url(#clip-path)">
                <path id="Caminho_385" data-name="Caminho 385" d="M90.135,8.259a32.093,32.093,0,0,1,.041-4.58A4.446,4.446,0,0,1,94.662,0q7.29-.009,14.579,0h.5V.646q0,3.57,0,7.14A2.779,2.779,0,0,0,112.7,10.8c2.322.019,4.643,0,6.965.005h.666v11.05c-.822,0-1.64-.021-2.456.006a11.272,11.272,0,0,0-2.281.178,4.392,4.392,0,0,0-3.365,4.425q-.022,5.134,0,10.268a.7.7,0,0,1-.5.779,3.6,3.6,0,0,0-1.8,4.939c.266.544.58,1.063.911,1.664-.212.019-.372.047-.533.047-5.155,0-10.309.011-15.464,0a4.631,4.631,0,0,1-4.818-4.831q0-5.429,0-10.858v-.652h.738q6.817,0,13.634,0a4.664,4.664,0,0,0,4.793-3.571,6.352,6.352,0,0,0,.169-1.516c.015-3.128.012-6.255.006-9.383a4.75,4.75,0,0,0-5.085-5.084H90.135Z" transform="translate(-75.837 0)" fill="#004070"/>
                <path id="Caminho_386" data-name="Caminho 386" d="M15.849,64.17c4.21,0,8.421.052,12.63-.022a3.037,3.037,0,0,1,3.2,3.182c-.072,3.2-.019,6.412-.022,9.618a2.864,2.864,0,0,1-3.018,3.044q-12.807,0-25.615,0A2.821,2.821,0,0,1,.011,77.021q-.022-4.957,0-9.913a2.77,2.77,0,0,1,2.972-2.935q6.433-.011,12.866,0m-1.86,2.609c-.256,0-.511,0-.767,0-.866.005-1.171.3-1.172,1.164q0,4.157,0,8.315c0,.778.323,1.106,1.106,1.128.491.014.985.022,1.474-.012a5.31,5.31,0,1,0-.642-10.595M5.436,73.153c.732-.034,1.382-.03,2.023-.1a2.952,2.952,0,0,0,2.61-2.815,2.917,2.917,0,0,0-2.324-3.329,20.75,20.75,0,0,0-3.052-.124c-.8-.013-1.128.333-1.129,1.153q0,2.8,0,5.6c0,.924-.007,1.848,0,2.772a.935.935,0,1,0,1.865.065c.017-.2.006-.393.006-.589,0-.861,0-1.723,0-2.634m17.76-4.5h.66c1.042,0,2.084.012,3.126-.006.692-.012,1.1-.406,1.082-.98-.021-.549-.43-.887-1.1-.889q-2.212-.007-4.423,0c-.944,0-1.256.317-1.257,1.282q0,4.01,0,8.019a2.868,2.868,0,0,0,.013.412.964.964,0,0,0,.947.878.945.945,0,0,0,.916-.966c.021-.687.006-1.376.006-2.064,0-.661,0-1.321,0-2.018,1.334,0,2.59.01,3.845,0a.943.943,0,0,0,1.05-.946A.955.955,0,0,0,27,70.442c-.53-.011-1.061,0-1.592,0H23.2Z" transform="translate(0 -54.034)" fill="#004070"/>
                <path id="Caminho_387" data-name="Caminho 387" d="M228.391,165.764v-.623q0-5.929,0-11.859a2.585,2.585,0,0,1,2.749-2.774q2.242-.005,4.485,0a2.616,2.616,0,0,1,2.841,2.873c.009,3.894,0,7.788,0,11.681v.736c.369.018.677.026.985.049a1.734,1.734,0,0,1,1.459,2.643c-.737,1.342-1.523,2.658-2.29,3.983q-1.756,3.034-3.517,6.064a1.818,1.818,0,0,1-3.384-.007q-2.864-4.943-5.718-9.892a1.743,1.743,0,0,1,.828-2.67,7.989,7.989,0,0,1,1.558-.2" transform="translate(-190.114 -126.779)" fill="#004070"/>
                <path id="Caminho_388" data-name="Caminho 388" d="M37.769,176.7v1.29q0,5.015,0,10.031a6.592,6.592,0,0,0,6.871,6.859q7.584,0,15.168,0h.644a4.5,4.5,0,0,1-3.662,3.881,8.051,8.051,0,0,1-1.289.107q-10.063.01-20.125,0a4.781,4.781,0,0,1-5.145-5.155q0-8.143,0-16.286V176.7Z" transform="translate(-25.465 -148.839)" fill="#004070"/>
                <path id="Caminho_389" data-name="Caminho 389" d="M235.712,9.017h-1.705q-2.92,0-5.84,0c-.862,0-1.186-.316-1.188-1.162,0-2.572,0-5.145,0-7.777a3.1,3.1,0,0,1,2.451.838q2.784,2.761,5.472,5.617a3.336,3.336,0,0,1,.811,2.486" transform="translate(-191.193 -0.059)" fill="#004070"/>
                <path id="Caminho_390" data-name="Caminho 390" d="M41.641,36.5v2.347H34.863c.424-1.8,3.626-2.921,6.778-2.347" transform="translate(-29.367 -30.618)" fill="#004070"/>
                <path id="Caminho_391" data-name="Caminho 391" d="M88.364,92.573a3.28,3.28,0,0,1,3.283,1.3,3.523,3.523,0,0,1,.209,3.832,3.375,3.375,0,0,1-3.491,1.644Z" transform="translate(-74.433 -77.91)" fill="#004070"/>
                <path id="Caminho_392" data-name="Caminho 392" d="M34.729,92.739c.53,0,1.036-.015,1.54,0a1.273,1.273,0,0,1,1.259,1.281,1.288,1.288,0,0,1-1.187,1.3c-.39.033-.784.009-1.176.01h-.436Z" transform="translate(-29.254 -78.114)" fill="#004070"/>
            </g>
        </svg>
    )
}

export default PDF;