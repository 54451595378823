import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 150px;
    align-items: center;
`;

export const TextRight = styled.text`
    font-size: 20px;
    font-weight: bold;
    color: #004070;
`;

export const Left = styled.div`
    width: 94px;
    height: 75px;
    background-color: #7BC7FF;
    border-radius: 15px 0px 15px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Right = styled.div`
    background-color: #7BC7FF;
    width: 256px;
    height: 55px;
    margin-top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 15px 0px;
`;