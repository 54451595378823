import React from 'react';

function Face(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="39.062" height="39.061" viewBox="0 0 39.062 39.061" fill="none" {...props}>
            <g id="Grupo_9" data-name="Grupo 9" transform="translate(0.5 0.5)">
                <path id="Caminho_56" data-name="Caminho 56" d="M19.531,38.561h0A19.031,19.031,0,0,1,.5,19.531h0a19.031,19.031,0,0,1,38.062,0h0A19.031,19.031,0,0,1,19.531,38.561Z" transform="translate(-0.5 -0.5)" fill="none" stroke="#7bc7ff" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Caminho_57" data-name="Caminho 57" d="M16.691,31.223h4.535V19.432h3.165l.337-3.948h-3.5V13.235c0-.931.181-1.3,1.048-1.3h2.454v-4.1h-3.14c-3.375,0-4.9,1.544-4.9,4.5v3.148H14.332v4h2.359Z" transform="translate(-0.5 -0.5)" fill="#7bc7ff"/>
            </g>
        </svg>
    )
}

export default Face;