import styled from 'styled-components';
import Adv from '../../assets/svg/Email';
import { Input, FormGroup, Label } from 'reactstrap';

export const CustomModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    width: 100vw;
    height: 100vh;
    background-color: #0664A2;
    opacity: 0.94;
    display: flex;
    justify-content: center;
    padding-top: 10%;
`;

export const DivModal = styled.div`
    width: 820px;
    height: 420px;
`;

export const HeaderModal = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Left = styled.div``;

export const Right = styled.div`
    cursor: pointer;
`;

export const ModalBody = styled.div`
    width: 820px;
    height: 380px;
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-top: 10px;
    opacity: 1;
`;

export const BodyLeft = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const DivIcone = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    min-width: 75px;
    height: 65px;
    border-right: 1px solid #D6D5D5;
`;

export const Body = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CustomLabel = styled(Label)`
    width: 80px;
    font-size: 14px;
    background-color: #FFFFFF;
    margin-left: 20px;
    z-index: 3;
    padding-left: 10px;
`;

export const CustomFormGroup = styled(FormGroup)`
    border: 2px solid #E0E0E0;
    border-radius: 20px;
    width: 800px;
    height: 150px;
    margin-top: -20px;
`;

export const CustomInput = styled(Input)`
    width: 770px;
    border: 0px;
    height: 130px;
    margin-top: 10px;
    margin-left: 10px;

    &:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
    }
`;

export const DivRight = styled.div`
    display: flex;
    justify-content: end;
    margin-right: 20px;
`;

export const CardText = styled.div`
    display: grid;
    padding: 10px 20px;
    width: 100%;
`;

export const TagTitleNext = styled.text`
    font-size: 11px;
    color: #9E9B9B;
    white-space: nowrap;
`;

export const TitleCardNext = styled.text`
    font-size: 14px;
    font-weight: bold;
    color: #000000;
`;

export const CustomAdv = styled(Adv)`
    & path {
        fill: ${({ color }) => { if (color === 'branco') { return '#FFFFFF'; } else if (color === 'cinza') { return '#9E9B9B'; } else { return '#3192D2'; }}};
    }
`;

export const TextWhite = styled.text`
    font-size: 25px;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 10px;
`;

export const CenterGrid = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 420px;
`;

export const TextBlue = styled.text`
    font-size: 25px;
    font-weight: bold;
    color: #0664A2;
`;
    
export const DivButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const ButtonCancela = styled.div``;
export const ButtonConfirma = styled.div``;

export const TextGreen = styled.text`
    font-size: 25px;
    font-weight: bold;
    color: #68A884;
    margin-top: -200px;
`;