import styled from 'styled-components';
import { Input } from 'reactstrap';
import Revistas from '../../assets/img/revistas.png';
import { Link } from "react-router-dom";

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: 40px;
`;

export const TitleBody = styled.div`
    font-size: 30px;
    font-weight: bold;
    color: #7BC7FF;
    margin-left: 15px;
    height: 50px;
    width: 200px;
`;

export const BodyInfo = styled.div`
    display: grid;
    width: 50%;

    @media (max-width: 1200px) {
        width: 100%;
        margin-top: -5px;
    }
`;

export const BodyInfoFlex = styled.div`
    display: grid;
    width: 50%;

    @media (max-width: 1200px) {
        width: 100%;
        display: flex;
        margin-top: 10px;
    }
`;

export const TextBody = styled.div`
    font-size: 30px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 1;
    margin-top: 15px;

    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

export const TextBodyPq = styled.text`
    font-size: 15px;
    margin-top: 10px;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;     /* fallback */
    max-height: 65px;      /* fallback */
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;

    @media (max-width: 1200px) {
        -webkit-line-clamp: 2; /* number of lines to show */
    }
`;

export const TextInput = styled.text`
    font-size: 13px;
    color: #FFFFFF;
    margin-top: 15px;
    margin-left: 10px;
`;

export const CustomInputText = styled(Input)`
    border: 0px;
    width: 90%;
    height: 30px;
    margin-left: 5px;
    background: #FFFFFF;
    border-radius: 15px;
    color: #000000;

    &::-webkit-input-placeholder {
        color: #7BC7FF;
    }

`;

export const Fundo = styled.div`
    background-color: #004070;
    height: 350px;
    border-top: 2px solid #F27C00;
    border-bottom: 2px solid #F27C00;

    @media (max-width: 768px) {
        height: 700px;
    }
`;

export const Direcao = styled.div`
    display: flex;

    @media (max-width: 1200px) {
        display: grid;
    }
`;

export const DivInput = styled.div`
    display: grid;
    width: 100%;

    @media (max-width: 1200px) {
        display: grid;
        width: 100%;    
    }
`;

export const DivButtonMobile = styled.div`
    display: none;

    @media (max-width: 1200px) {
        display: flex;
        margin-top: 20px;    
    }
`;

export const DivButton = styled.div`
    margin-top: 30px;
    @media (max-width: 1200px) {
        display: none;   
    }
`;

export const ImagemJornal = styled.div`
    background-image: url(${Revistas});
    max-height: 200px;
    min-height: 200px;
    background-repeat: no-repeat;
    background-color: #004070;
    background-position: center;
    Background-size: contain;
    position: relative;

    @media (max-width: 1200px) {
        max-height: 100px;
        min-height: 100px;
    }

    @media (max-width: 769px) {
        max-height: 150px;
        min-height: 150px;
    }
`;

export const DivJornal = styled(Link)`
    text-decoration: none;
    min-width: 50%;
    min-height: 50%;
`;

export const TextRed = styled.text`
    font-size: 14px;
    color: red;
`;