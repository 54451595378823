import React, { useState, useEffect, useRef } from 'react';
import * as S from './styles';
import MaiorPag from '../../assets/svg/Maior';
import MenorPag from '../../assets/svg/Menor';
import Maps from '../Maps';
import MapsMobile from '../MapsMobile';
import MenorPq from '../../assets/svg/MenorPq';
import MaiorPq from '../../assets/svg/MaiorPq';
import Axios from '../../utils/Axios';

const Sedes = (props) => {
    const [dados, setDados] = useState([]);
    const carousel = useRef(null);
    const carouselPq = useRef(null);

    const handleMoviLeft = () => {
        carousel.current.scrollLeft -= carousel.current.offsetWidth;
    };

    const handleMoviRight = () => {
        carousel.current.scrollLeft += carousel.current.offsetWidth;
    };

    const handleMoviLeftPq = () => {
        carouselPq.current.scrollLeft -= carouselPq.current.offsetWidth+20;
    };

    const handleMoviRightPq = () => {
        carouselPq.current.scrollLeft += carouselPq.current.offsetWidth+20;
    };
    
    useEffect( () => {
        let token = localStorage.getItem('token');
        Axios.defaults.headers.Authorization = `Bearer ${token}`;
        Axios.get(`/sedes`).then((response) => {
          setDados(response.data)
        })
    }, []);
    
    return (
        <>
            <S.Container>
                <S.ButtonCardEvento onClick={() => handleMoviLeft()} >
                    <MenorPag />
                </S.ButtonCardEvento>
                <S.DivCardEventos ref={carousel}>
                    {dados !== undefined && dados.map((item, index) => (
                        item.google !== null ?
                            <Maps nome={item.nome} texto={item.texto} google={item.google} key={index} endereco={item.endereco} cep={item.cep} cidade={item.cidade} bairro={item.bairro} estado={item.estado} />
                        : ""
                    ))}
                </S.DivCardEventos>
                <S.ButtonCardEvento onClick={() => handleMoviRight()} >
                    <MaiorPag />
                </S.ButtonCardEvento>
            </S.Container>
            <S.DivCardEventosMobile ref={carouselPq}>
                <S.ButtonAcao>
                    <MenorPq onClick={() => handleMoviLeftPq()} />
                    <MaiorPq onClick={() => handleMoviRightPq()}/>
                </S.ButtonAcao>
                <MapsMobile />
                <MapsMobile />
            </S.DivCardEventosMobile>
        </>
    )
}

export default Sedes;