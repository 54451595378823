import styled from 'styled-components';
    
export const Fundo = styled.div`
    display: grid;
    text-align: center;
    justify-content: center;
    height: 50px;
    margin-top: 20px;
`;

export const Nome = styled.text`
    font-size: 20px;
    color: #7BC7FF;
    font-weight: bold;
`;

export const Cargo = styled.text`
    font-size: 15px;
    color: #707070;
`;