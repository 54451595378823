import React from 'react';
import * as S from './styles';
import Tag from '../../assets/img/tag.png';

const Maps = (props) => {
    const googleContato = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.9919348622293!2d-51.223155784278575!3d-30.037089237896257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951979aa9f34e7bf%3A0xd7a390e372fa9735!2sR.%20da%20Rep%C3%BAblica%2C%2092%20-%20Cidade%20Baixa%2C%20Porto%20Alegre%20-%20RS%2C%2090050-320!5e0!3m2!1spt-BR!2sbr!4v1655746652260!5m2!1spt-BR!2sbr";

    return (
        <S.Fundo>
            <iframe
                src={googleContato}
                width="100%"
                height="333px"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                title="maps"
            />
            <S.Infos>
                <S.Right>
                    <S.DivText>
                        <S.Title>Sede Administrativa</S.Title>
                        <S.Text>
                        A Sede Administrativa localiza-se na Rua da República, 92, Cidade Baixa, Porto Alegre. É composta pela recepção, sala da presidência, diretoria administrativa, assessoria jurídica, assessoria de comunicação, biblioteca, auditório, copa e cozinha. Possui ainda um pátio interno e uma churrasqueira.
                        </S.Text>
                    </S.DivText>
                    <S.Divider />
                    <S.DivEnd>
                        <img src={Tag} alt="" />
                        <S.End>
                            <S.TextBold>Rua da República, 92</S.TextBold>
                            <S.TextNormal>Cidade Baixa - 90050-320</S.TextNormal>
                            <S.TextBold>Porto Alegre - RS</S.TextBold>
                        </S.End>
                    </S.DivEnd>
                </S.Right>
            </S.Infos>
        </S.Fundo>
    )

}

export default Maps;