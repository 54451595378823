import React from 'react';
import ReactPlayer from  'react-player/youtube';

const Multimidia = (props) => {

    return (
        <ReactPlayer 
            height={props.height} 
            style={{ maxWidth: props.maxWidth, minWidth: props.minWidth, borderRadius: "50px" }} 
            controls={true} 
            url={`https://www.youtube.com/watch?v=${props.urlYoutube}`} />
    )
}

export default Multimidia;