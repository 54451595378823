import React from 'react';
import * as S from './styles';
// import logoFooter from '../../assets/img/logo_footer.png';
import Face from '../../assets/svg/Face';
import Insta from '../../assets/svg/Insta';
import Twitter from '../../assets/svg/Twitter';
import Whats from '../../assets/svg/Whats';
import { Container, Row, Col } from 'reactstrap';
import Logo from '../../assets/img/logo_amarelo.png';

const Footer = () => {
    return (
        <>
            <S.Fundo>
                <Container style={{ maxWidth: "94%" }}>
                    <Row>
                        <Col md={6}>
                            <S.Left>
                                <img src={Logo} alt="" /> 
                                <S.TextRedes>Siga o Sindicaixa nas redes sociais.</S.TextRedes>
                                <S.DivRedes>
                                <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sindicaixa.sindicato/`}>
                                    <Face />
                                </a>
                                <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/sindicaixa/`}>
                                    <Insta />
                                </a>
                                <a target="_blank" rel="noreferrer" href={`https://twitter.com/Sindicaixa`}>
                                    <Twitter />
                                </a>
                                <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=5551998784504`}>
                                    <Whats />
                                </a>
                                </S.DivRedes>
                            </S.Left>
                        </Col>
                        <Col md={6}>
                            <S.Right>
                                <Row>
                                    <Col md={12}>
                                        <S.NavLink to="/" >HOME</S.NavLink>
                                        <S.NavLink to="/sobre" >SOBRE</S.NavLink>
                                        <S.NavLink to="/sedes" >SEDES</S.NavLink>
                                        <S.NavLink to="/noticias" >NOTÍCIAS</S.NavLink>
                                        <S.NavLink to="/multimidia" >MULTIMÍDIA</S.NavLink>
                                        <S.NavLink to="/links" >LINKS</S.NavLink>
                                        <S.NavLink to="/contato" >CONTATO</S.NavLink>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "80px" }}>
                                    <Col md={1}>
                                        <S.CustomTelefone />
                                    </Col>
                                    <Col md={4}>
                                        <S.TextContato>51 32248049</S.TextContato>
                                    </Col>
                                    <Col md={1}>
                                        <S.CustomEmail />
                                    </Col>
                                    <Col md={6}>
                                        <S.TextContato>sindicaixa@sindicaixars.org.br</S.TextContato>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <S.Informacoes>
                                            <S.Title>INFORMAÇÕES</S.Title>
                                            <S.TextLink to="/sobre">O Sindicato</S.TextLink>
                                            <S.TextLink to="/sobre">Regionais</S.TextLink>
                                            <S.TextLink to="/sobre">Estatuto</S.TextLink>
                                            <S.TextLink to="/sobre">Diretoria</S.TextLink>
                                            <S.TextLink to="/sobre">Conselho Fiscal</S.TextLink>
                                            <S.TextLink to="/sobre">CSP</S.TextLink>
                                        </S.Informacoes>
                                    </Col>
                                    <Col md={4}>
                                        <S.Informacoes>
                                            <S.Title>SOBRE</S.Title>
                                            <S.TextLink to="/noticias">Notícias</S.TextLink>
                                            <S.TextLink to="/sedes">Sede Social</S.TextLink>
                                            <S.TextLink to="/sedes">Sede Administrativa</S.TextLink>
                                            <S.TextLink to="/multimidias">Multimídia</S.TextLink>
                                            <S.TextLink to="/jornal">Jornal</S.TextLink>
                                            <S.TextLink to="/links">Links</S.TextLink>
                                        </S.Informacoes>
                                    </Col>
                                    <Col md={4}>
                                        <S.Informacoes>
                                            <S.Title>CONTATO</S.Title>
                                            <S.TextLink to="/contato">Fale com Sindicaixa</S.TextLink>
                                            <S.TextLink to="/contato">Receba Informativos</S.TextLink>
                                            <S.TextLink to="/contato">Localização</S.TextLink>
                                        </S.Informacoes>
                                    </Col>
                                </Row>
                            </S.Right>
                            <S.RightTablet>
                                <S.DivTelefone>
                                    <S.CustomTelefone />
                                    <S.TextContato>51 32248049</S.TextContato>
                                </S.DivTelefone>
                                <S.DivEmail>
                                    <S.CustomEmail />
                                    <S.TextContato>sindicaixa@sindicaixars.org.br</S.TextContato>
                                </S.DivEmail>
                            </S.RightTablet>
                        </Col>
                    </Row>
                </Container>
            </S.Fundo>
            <S.FundoMobile>
                <S.HeaderMenu>
                    <S.DivCenter to="/">
                        <img src={Logo} alt="" width={"212px"} height={"99px"} />
                    </S.DivCenter>
                </S.HeaderMenu>
                <S.BodyMenu>
                    <S.NavLink to="/" >HOME</S.NavLink>
                    <S.NavLink to="/sobre" >SOBRE</S.NavLink>
                    <S.NavLink to="/sedes" >SEDES</S.NavLink>
                    <S.NavLink to="/noticias" >NOTÍCIAS</S.NavLink>
                    <S.NavLink to="/multimidia" >MULTIMÍDIA</S.NavLink>
                    <S.NavLink to="/links" >LINKS</S.NavLink>
                    <S.NavLink to="/contato" >CONTATO</S.NavLink>
                    <S.Divider />
                    <S.Text>Siga o Sindicaixa nas redes sociais.</S.Text>
                    <S.RedeSociaisMobile style={{ marginTop: "10px" }}>
                        <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sindicaixa.sindicato/`}>
                            <Face />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/sindicaixa/`}>
                            <Insta />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://twitter.com/Sindicaixa`}>
                            <Twitter />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=5551998784504`}>
                            <Whats />
                        </a>
                    </S.RedeSociaisMobile>
                    <S.Contato>
                        <S.DivTelefone>
                            <S.CustomTelefone />
                            <S.TextContatoTelefone>
                                51 32248049
                            </S.TextContatoTelefone>
                        </S.DivTelefone>
                        <S.DivEmail>
                            <S.CustomEmail />
                            <S.TextContatoEmail>
                                sindicaixa@sindicaixars.org.br
                            </S.TextContatoEmail>
                        </S.DivEmail>
                    </S.Contato>
                </S.BodyMenu>
            </S.FundoMobile>
        </>
    );
};

export default Footer;