import styled from 'styled-components';
import { Link } from "react-router-dom";

export const Container = styled.div`
    display: grid;
    justify-content: center;
    text-align: center;
    max-width: 100%;
    height: 450px;
    margin-bottom: 30px;
`;

export const DivLink = styled(Link)`
    text-decoration: none;
`;

export const Body = styled.div`
    max-height: 350px;
    min-height: 350px;
    min-width: 250px;
    background-image: url(${(picture) => `http://sindicaixars.web227.uni5.net/api/storage/app/public/${picture.picture}`});
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    background-position: center;
    Background-size: contain;
    position: relative;
    border-radius: 15px;

    @media (max-width: 1200px) {
        max-height: 470px;
        min-height: 470px;
    }
`;
    
export const Title = styled.text`
    color: #004070;
    font-size: 35px;
    font-weight: bold;
`;

export const Text = styled.text`
    color: #707070;
    font-size: 15px;
`;

export const Grid = styled.div`
    display: grid;
`;