import React  from 'react';
import * as S from './styled';

const CardLinks = (props) => {
    return (
        <S.Container>
            <a target="_blank" rel="noreferrer" href={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${props.pdf}`} style={{ textDecoration: "none" }}>
                <S.Body picture={props.imagem} />
                <S.Grid>
                    <S.Title>{props.titulo}</S.Title>
                    <S.Text>{props.mes} - {props.ano}</S.Text>
                </S.Grid>
            </a>
        </S.Container> 
    )
}

export default CardLinks;