import React, { useState } from 'react';
import * as S from './styles';
import { Container, Row, Col } from 'reactstrap';
import LogoPq from '../../assets/img/logo_pq.png';
import ButtonPrimary from '../../components/ButtonPrimary';
import Axios from '../../utils/Axios';
import Modal from '../../components/Modal';

const Newsletter = (props) => {
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [modal, setModal] = useState(false);
    const [retorno, setRetorno] = useState(false);
    const [erro, setErro] = useState(false);
    
    const handleNews = async () => {
        if(!nome || !email) {
            setErro(true)
            setTimeout(() => {
                setErro(false)
              }, 3000);
        }else{
            setModal(true);
            await Axios.post(`/news`,{
                nome: nome,
                email: email,
              }, {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            ).then((res) => {
                setRetorno(true);
                setNome("");
                setEmail("");
            })
        }
    };

    return (
        <S.Fundo>
            {modal && 
                <Modal
                    modal={modal} setModal={setModal} 
                    acao="news" 
                    retorno={retorno}
                />
            }
            <Container style={{ maxWidth: "94%"}}>
                <Row>
                    <Col xl={6} md={8}>
                        <S.DivTitulos>
                            <img src={LogoPq} alt="" />
                            <S.TitleBody>Newsletter</S.TitleBody>
                        </S.DivTitulos>
                        <S.Direcao>
                            <S.BodyInfo>
                                <S.TextBody>Cadastre seu e-mail e receba nossos informativos.</S.TextBody>
                                {/* <S.TextBodyPq>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper.</S.TextBodyPq> */}
                            </S.BodyInfo>
                            <S.BodyInfoFlex>
                                <S.DivInput>
                                    <S.TextInput>Nome</S.TextInput>
                                    <S.CustomInputText
                                        id="nome"
                                        type="text"
                                        name="nome"
                                        onChange={(e) => setNome(e.target.value)}
                                        value={nome}
                                    />
                                </S.DivInput>
                                <S.DivInput>
                                    <S.TextInput>Email</S.TextInput>
                                    <S.CustomInputText
                                        id="email"
                                        type="text"
                                        name="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </S.DivInput>
                                {erro && <S.TextRed>Os campos devem ser preenchidos!</S.TextRed>}
                                <S.DivButton onClick={() => handleNews()}>
                                    <ButtonPrimary label="cadastrar" size="95%" />
                                </S.DivButton>
                            </S.BodyInfoFlex>
                            <S.DivButtonMobile onClick={() => handleNews()}>
                                <ButtonPrimary label="cadastrar" size="95%" />
                            </S.DivButtonMobile>
                        </S.Direcao>
                    </Col>
                    <Col xl={6} md={4}>
                        <S.DivTitulos>
                            <img src={LogoPq} alt="" />
                            <S.TitleBody>O jornal</S.TitleBody>
                        </S.DivTitulos>
                        <S.Direcao>
                            <S.BodyInfo>
                                <S.ImagemJornal />
                            </S.BodyInfo>
                            <S.BodyInfo>
                                <S.TextBody>Edições Jornal</S.TextBody>
                                {/* <S.TextBodyPq style={{ marginBottom: "30px" }}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</S.TextBodyPq> */}
                                <S.DivJornal to="/jornal">
                                    <ButtonPrimary label="leia mais" size="95%" />
                                </S.DivJornal>
                            </S.BodyInfo>
                        </S.Direcao>
                    </Col>
                </Row>
            </Container>
        </S.Fundo>
    )
}

export default Newsletter;