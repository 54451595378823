import React from 'react';

function Check(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="85.277" height="72.071" viewBox="0 0 85.277 72.071" fill="none" {...props}>
            <path id="Caminho_1018" data-name="Caminho 1018" d="M-2587.74-1958.338a3.845,3.845,0,0,1-5.438,0l-14.566-14.566a3.986,3.986,0,0,0-5.637,0l-4.434,4.434a3.986,3.986,0,0,0,0,5.637l24.717,24.693a3.725,3.725,0,0,0,5.259.006l51.939-51.642a7.443,7.443,0,0,0,2.195-5.278v-11.76a2.3,2.3,0,0,0-3.932-1.629Z" transform="translate(2618.982 2009.12)" fill="#68a884"/>
        </svg>
    )
}

export default Check;